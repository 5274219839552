import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListCondensed from './ListCondensed/ListCondensed.jsx';
import views from '../../../../constants/views';
import { type as historyTypes } from '../../../../stores/bookingHistory/constants';
import { isEventView, previousView } from '../../../../selectors/viewport';
import { clearDialogs } from '../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../stores/dialog/constants';
import { setCurrentView } from '../../../../stores/viewport/actions';
import { addDialog } from '../../../../stores/dialog/actions';
import './Viewport.scss';
import Button from '../../../formInput/Button/Button';
import { AV_PRODUCT } from '../../../../constants/audioVisual';

const Admin = React.lazy(() => import('./Admin/Admin.jsx'));
const BookingHistory = React.lazy(() =>
    import('./BookingHistory/BookingHistory.jsx')
);
const TimeLine = React.lazy(() => import('././Timeline/TimeLine.jsx'));
const AVBookingHistory = React.lazy(() =>
    import('./AVBookingHistory/AVBookingHistory.jsx')
);
const AutobookingListOverview = React.lazy(() =>
    import('./AutobookingListOverview/AutobookingListOverview.jsx')
);
const Inspector = React.lazy(() => import('./Inspector/Inspector.jsx'));

const getProductIdByViewType = viewType => {
    switch (viewType) {
        case views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY:
            return AV_PRODUCT.ID.LCO;
        case views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY:
            return AV_PRODUCT.ID.LCR;
        case views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY:
            return AV_PRODUCT.ID.LCT;
        default:
            return undefined;
    }
};

const getBookingHistoryTypeByViewType = viewType => {
    switch (viewType) {
        case views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY:
            return historyTypes.LCO;
        case views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY:
            return historyTypes.LCR;
        case views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY:
            return historyTypes.LCT;
        case views.VIEWPORT_TYPE_BOOKING_HISTORY:
            return historyTypes.LO;
        default:
            console.error('Invalid viewType: ' + viewType);
            return undefined;
    }
};

class ViewPort extends React.Component {
    getXhrErrorDiv(xhr) {
        if (xhr.code && xhr.code === 401) {
            return (
                <div className="xhr-error-wrapper">
                    Oops.
                    <div className="properties">
                        You have been logged out. Click below to log back in
                        <br />
                        <br />
                        <Button
                            type={Button.types.LARGE_BLUE_3D}
                            onClick={() => (window.location.href = '/login')}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.xhrError !== null && prevProps.isDisplayingDialog) {
            prevProps.clearDialogs();
        }
        if (this.props.xhrError && !this.props.isDisplayingDialog) {
            let xhr = this.props.xhrError;
            this.props.addDialog(dialogTypes.XHR_ERROR, {
                errorMessage:
                    xhr.code === 500 || xhr.code === 800
                        ? 'We lost connection to the server. Check your internet connection, or try reloading.'
                        : xhr.message || JSON.stringify(xhr),
                showReloadButton:
                    xhr.code === 500 || xhr.code === 1004 || xhr.code === 800
                        ? true
                        : false,
                hideCornerX:
                    xhr.code === 500 || xhr.code === 1004 || xhr.code === 800
                        ? true
                        : false,
            });
        }
    }

    goToPreviousView = () => {
        this.props.setCurrentView(this.props.previousView);
    };

    render() {
        const isBusy = this.props.isFetching;

        let content = '';

        const errorContent =
            this.props.xhrError && this.getXhrErrorDiv(this.props.xhrError);

        if (!(isBusy && this.props.isEventView)) {
            if (errorContent) {
                content = errorContent;
            } else {
                const v = this.props.currentView;
                if (v === views.VIEWPORT_TYPE_LIST_CONDENSED) {
                    content = <ListCondensed />;
                } else if (v === views.VIEWPORT_TYPE_TIMELINE_GADGET) {
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            <TimeLine />
                        </Suspense>
                    );
                } else if (v === views.VIEWPORT_TYPE_BOOKING_HISTORY) {
                    const historyType = getBookingHistoryTypeByViewType(v);
                    const subProductId = getProductIdByViewType(v);
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            <BookingHistory
                                type={historyType}
                                subProductId={subProductId}
                                tableName={`${historyType} Booking History`}
                                key={`booking_history_${historyType}`}
                            />
                        </Suspense>
                    );
                } else if (
                    [
                        views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY,
                        views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY,
                        views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY,
                    ].includes(v)
                ) {
                    const historyType = getBookingHistoryTypeByViewType(v);
                    const subProductId = getProductIdByViewType(v);
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            {' '}
                            <AVBookingHistory
                                type={historyType}
                                subProductId={subProductId}
                                tableName={`${historyType} Booking History`}
                                key={`booking_history_${historyType}`}
                            />
                        </Suspense>
                    );
                } else if (
                    v === views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW
                ) {
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            <AutobookingListOverview />
                        </Suspense>
                    );
                } else if (v === views.VIEWPORT_TYPE_ADMIN) {
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            <Admin />
                        </Suspense>
                    );
                } else if (v === views.VIEWPORT_TYPE_INSPECTOR) {
                    content = (
                        <Suspense
                            fallback={
                                <div style={{ padding: '5px' }}>Loading...</div>
                            }
                        >
                            <Inspector />
                        </Suspense>
                    );
                }
            }
        }

        return <div className="src-viewport">{content}</div>;
    }
}

ViewPort.propTypes = {
    previousView: PropTypes.string,
    isEventView: PropTypes.bool.isRequired,
    currentView: PropTypes.string.isRequired,
    xhrError: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    size: PropTypes.object.isRequired,
    isDisplayingDialog: PropTypes.bool.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    clearDialogs: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    previousView: previousView(state.viewport.viewHistory),
    isEventView: isEventView(state.viewport.currentView),
    currentView: state.viewport.currentView,
    xhrError: state.calendar.xhrError,
    isFetching: state.calendar.isFetching,
    size: state.calendar.size,
    isDisplayingDialog: state.dialog.types.length > 0,
});

const mapDispatchToProps = {
    setCurrentView,
    clearDialogs,
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPort);
