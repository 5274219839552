import PropTypes from 'prop-types';
import SportIcon from '../../icons/SportIcon/SportIcon';
import FontIcon from '../../icons/FontIcon/FontIcon';
import MatchEventTime from './MatchEventTime';
import HighlightedContent from '../HighlightedContent/HighlightedContent';
import LiveStatusIcon from '../../icons/LiveStatusIcon/LiveStatusIcon';
import './MatchEvent.scss';
import uriToId from '../../../utils/uriToId';
import { AV_PRODUCT } from '../../../constants/audioVisual';
import classnames from 'classnames';
import { simulatedCategories } from '../../../constants/sports';
import AddOns from '../AddOns/AddOns';
import CommonProductBookingStatus from '../CommonProductBookingStatus';

function MatchEvent(props) {
    const { inBasket = false, isCondensed = false } = props;
    function getIsStreaming() {
        if (!props.event.streamingInfo) return false;
        let isStreaming = false;

        ['lco', 'lcr'].forEach(productKey => {
            const streamStatusId = props.event.streamingInfo[productKey]
                ? parseInt(
                      uriToId(
                          props.event.streamingInfo[productKey].streamStatusUri
                      ),
                      10
                  )
                : null;

            if (
                streamStatusId &&
                streamStatusId < AV_PRODUCT.STREAM_STATUS.ERROR &&
                [
                    AV_PRODUCT.STREAM_STATUS.ON_AIR_BROADCAST,
                    AV_PRODUCT.STREAM_STATUS.ON_AIR_READY,
                ].includes(streamStatusId)
            ) {
                isStreaming = true;
            }
        });
        return isStreaming;
    }

    function getSimulatedIcon() {
        const simTooltip =
            'The Simulated Reality product offering might be subject to applicable territorial conditions,\n' +
            'such as approval by regulatory authorities or other compliance requirements.\n' +
            'It is your obligation to comply with the legislation of each jurisdiction in which you are operating.';
        return simulatedCategories.includes(props.event.realCategoryId) ? (
            <FontIcon title={simTooltip} icon="" />
        ) : (
            ''
        );
    }

    return (
        <div
            className={classnames('match-event', 'calendar-event', {
                'in-basket': inBasket,
                'event-condensed-row': isCondensed,
                'in-timeline': props.inTimeLine,
                'event-xtra-small': props.isExtraSmall,
                'event-xtra-xtra-small': props.isExtraExtraSmall,
                'event-expanded': props.isExpanded,
                recommended: props.isRecommended,
                'products-row': props.isNewProductsUIEnabled,
                piwikTrackContent: props.isRecommended,
                piwikContentPiece: props.isRecommended,
            })}
        >
            <div className="sport">
                <SportIcon sportId={props.event.sportId} />
                {getSimulatedIcon()}
            </div>

            <div className="time textline-400" title="Start Date">
                <MatchEventTime startDateTimestamp={props.event.startDate} />
            </div>

            {!inBasket && (
                <div className="live-status">
                    <LiveStatusIcon
                        liveStatus={props.event.status}
                        isStreaming={getIsStreaming()}
                        liveStatusText={props.event.statusText}
                        access={props.access}
                    />
                    {props.insideMaintenanceWindow && (
                        <FontIcon
                            title="Match is inside the maintenance window"
                            icon=""
                        />
                    )}
                </div>
            )}

            <div
                className="homeTeam textline-400"
                title={`Home Team: ${props.event.homeTeam}`}
            >
                <HighlightedContent
                    content={props.event.homeTeam}
                    search={props.freeTextSearchPhrase}
                />
            </div>
            <div
                className="awayTeam textline-400"
                title={`Away Team: ${props.event.awayTeam}`}
            >
                <HighlightedContent
                    content={props.event.awayTeam}
                    search={props.freeTextSearchPhrase}
                />
            </div>
            <div
                className="category textline-400"
                title={`Category: ${props.event.realCategoryName}`}
            >
                <HighlightedContent
                    content={props.event.realCategoryName}
                    search={props.freeTextSearchPhrase}
                />
            </div>
            {!inBasket && (
                <>
                    <div
                        className="tournament textline-400"
                        title={`Tournament: ${props.event.tournamentName}`}
                    >
                        <HighlightedContent
                            content={props.event.tournamentName}
                            search={props.freeTextSearchPhrase}
                        />
                    </div>
                    <CommonProductBookingStatus {...props} />
                    {props.isNewProductsUIEnabled && (
                        <AddOns event={props.event} />
                    )}

                    {!props.inTimeLine && (
                        <div
                            className="options"
                            onClick={mouseEvent => {
                                mouseEvent.preventDefault();
                                props.openContextMenu(
                                    props.event,
                                    mouseEvent.pageX,
                                    mouseEvent.pageY
                                );
                            }}
                        >
                            <FontIcon title="Options" icon="" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

MatchEvent.propTypes = {
    event: PropTypes.object.isRequired,
    isCondensed: PropTypes.bool,
    freeTextSearchPhrase: PropTypes.array.isRequired,
    insideMaintenanceWindow: PropTypes.bool.isRequired,
    inBasket: PropTypes.bool,
    access: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    inTimeLine: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isRecommended: PropTypes.bool,
    isExtraSmall: PropTypes.bool,
    openContextMenu: PropTypes.func,
    isExtraExtraSmall: PropTypes.bool,
    bookmakerInfo: PropTypes.object.isRequired,
    recommenderSettings: PropTypes.object,
    isNewProductsUIEnabled: PropTypes.bool,
};

export default MatchEvent;
