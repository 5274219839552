import { AV_PRODUCT_ID } from './audioVisual';

export const adapterIds = {
    LIVEODDS: 0,
    BETPAL: 5,
    LCO: 2,
    LCT: 4,
    LCR: 3,
};

export const adapterLcProductIds = {
    [adapterIds.LCO]: AV_PRODUCT_ID.LCO,
    [adapterIds.LCR]: AV_PRODUCT_ID.LCR,
    [adapterIds.LCT]: AV_PRODUCT_ID.LCT,
};

export const adapterLcProducts = {
    [adapterIds.LCO]: 'lco',
    [adapterIds.LCR]: 'lcr',
    [adapterIds.LCT]: 'lct',
};

export const responseStatus = {
    SUCCESS: 1,
    FAILURE: 2,
    PRICE_CHANGE: 3,
};

export const eventAction = {
    NONE: 0,
    SUCCESS: 1,
    FAIL: 2,
};

export const BOOKING_STATUS = {
    NOT_SET: 0,
    BOOKABLE: 1,
    BOOKED: 2,
    BUYABLE: 3,
    NOT_AVAILABLE: 4,
};

export const BOOKING_STATUS_NAME = {
    0: 'Not set',
    1: 'Bookable',
    2: 'Booked',
    3: 'Buyable',
    4: 'Not set',
};

export const eventReason = {
    NOT_SET: 0,
    BOOKABLE_OK: 10,
    BOOKED_DB: 20,

    BOOKABLE_PRICE_CHANGE: 70,
    NOT_AVAILABLE_STREAMING: 71,
    ACTION_STREAMING_FULL_PACKAGE: 72,
    ACTION_STREAMING_NO_COUNTRIES: 73,
    ACTION_STREAMING_BOOK_ERROR: 74,
};

export const adapterConstants = {
    [adapterIds.LIVEODDS]: {
        name: 'Live Odds / Live Data',
        products: ['lo', 'ld'],
        failedBookingMessage:
            'Some matches may not have been booked because of a price change. Please check and book again.',
        showPrices: true,
        showProduct: true,
        bookBtnText: 'Book in Live Odds / Live Data',
    },
    [adapterIds.BETPAL]: {
        name: 'Betpal',
        products: ['bp'],
        failedBookingMessage:
            'Some matches may not have been booked. Please try again',
        showPrices: false,
        showProduct: false,
        bookBtnText: 'Open BetPal booking',
    },
    [adapterIds.LCO]: {
        name: 'LCO',
        products: ['lco'],
        failedBookingMessage:
            'Some matches may not have been booked. Please try again or ' +
            'contact your Betradar Sales Key Account or sales@betradar.com',
        showPrices: false,
        showProduct: false,
        bookBtnText: 'Book in Live Channel Online',
    },
    [adapterIds.LCR]: {
        name: 'LCR',
        products: ['lcr'],
        failedBookingMessage:
            'Some matches may not have been booked. Please try again or ' +
            'contact your Betradar Sales Key Account or sales@betradar.com',
        showPrices: false,
        showProduct: false,
        bookBtnText: 'Book in Live Channel Retail',
    },
    [adapterIds.LCT]: {
        name: 'LCT',
        products: ['lct'],
        failedBookingMessage:
            'Some matches may not have been booked. Please try again or ' +
            'contact your Betradar Sales Key Account or sales@betradar.com',
        showPrices: false,
        showProduct: false,
        bookBtnText: 'Book in Live Channel Trading',
    },
};
