import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './productBookingStatus.scss';
import _ from 'underscore';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_NAME,
} from '../../constants/shoppingBasket';
import { PdsLabel, PdsTooltip } from '@podium-design-system/react-components';

const ProductCountWithTooltip = ({
    event,
    bookingStatusId,
    groupedProdStatus,
}) => {
    return bookingStatusId.map(statusKey => {
        const productsGroup = groupedProdStatus[statusKey];
        return (
            <PdsTooltip
                key={event.id + statusKey}
                className={BOOKING_STATUS_NAME[statusKey]}
                size="md"
                arrow={true}
                preferredPosition="top"
                intensity="low"
                surface="on-light"
                visible={!!productsGroup}
            >
                <div
                    slot="text"
                    style={{
                        fontSize: '12px',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        display: 'flex',
                    }}
                >
                    {productsGroup?.map((prod, index) => (
                        <Fragment key={index}>
                            <span>{prod.type}</span>
                            {index !== productsGroup.length - 1 && (
                                <div className="dot-separator">.</div>
                            )}
                        </Fragment>
                    ))}
                </div>

                <PdsLabel
                    size="md"
                    color={
                        statusKey === BOOKING_STATUS.BOOKED
                            ? 'positive'
                            : statusKey === BOOKING_STATUS.BOOKABLE
                            ? 'positive'
                            : 'attention'
                    }
                    intensity={
                        statusKey === BOOKING_STATUS.BOOKED ? 'high' : 'low'
                    }
                    border={true}
                    padding="tight"
                    surface="on-light"
                    fixedWidth="20px"
                    style={{ '--height': '20px' }}
                >
                    {productsGroup ? productsGroup.length : 0}
                </PdsLabel>
            </PdsTooltip>
        );
    });
};

function ProductBookingStatusV2(props) {
    const groupedProdStatus = _.groupBy(
        props.event.products,
        'bookingStatusId'
    );
    const bookingStatusId = [
        BOOKING_STATUS.BOOKED,
        BOOKING_STATUS.BOOKABLE,
        BOOKING_STATUS.BUYABLE,
    ];
    const products = ['lo', 'ld', 'bp', 'lco', 'lcr', 'lct'];

    function getNumberProductsBooked() {
        let productsBooked = 0;
        products.forEach(prod => {
            if (
                props.event.products[prod] &&
                props.event.products[prod].bookingStatusId === 2
            ) {
                productsBooked++;
            }
        });
        return productsBooked;
    }

    let content = <div></div>;
    if (!props.inTimeLine) {
        content = (
            <ProductCountWithTooltip
                event={props.event}
                bookingStatusId={bookingStatusId}
                groupedProdStatus={groupedProdStatus}
            />
        );
        // or any other fallback if the key doesn't exist
    } else if (props.isExtraSmall && !props.isExpanded) {
        content = (
            <div className="product-booking-status">
                <div className="product-number">
                    {getNumberProductsBooked()}
                </div>
            </div>
        );
    } else {
        content = (
            <div className="product-booking-status">
                <ProductCountWithTooltip
                    event={props.event}
                    bookingStatusId={bookingStatusId}
                    groupedProdStatus={groupedProdStatus}
                />
            </div>
        );
    }

    return content;
}

ProductBookingStatusV2.propTypes = {
    recommenderSettings: PropTypes.object,
    event: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    isExtraSmall: PropTypes.bool,
    isExtraExtraSmall: PropTypes.bool,
    isExpanded: PropTypes.bool,
    inTimeLine: PropTypes.bool,
};

export default ProductBookingStatusV2;
