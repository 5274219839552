import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Xhr from '../../../../../utils/Xhr';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import TextInput from '../../../../formInput/TextInput/TextInput';
import Button from '../../../../formInput/Button/Button';
import './inspector.scss';

class Inspector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookmakerId: this.props.defaultBookmakerId,
            uri: this.props.defaultUri,
            isLoading: false,
            matchSelected: false,
        };
    }

    loadLoInfo() {
        Xhr.backendRequest(
            `/extended-event-info/${this.state.uri}/${this.state.bookmakerId}`,
            null,
            'GET',
            null,
            false
        ).then(
            response => {
                this.setState({
                    loInfo: response.data,
                    isLoading: false,
                    matchSelected: true,
                });
            },
            error => {
                this.setState({
                    error: true,
                    errorMsg: error.message,
                });
            }
        );
    }

    onChangeURI = uri => {
        this.setState({ uri: uri });
    };

    onChangeBookmakerId = bookmakerId => {
        this.setState({ bookmakerId: bookmakerId });
    };

    getForm() {
        return (
            <div className="form">
                <TextInput
                    labelText="URI"
                    className="inspectorInputContainer"
                    onChange={this.onChangeURI}
                    value={this.state.uri}
                    inputClassName="inspectorInput"
                />
                <TextInput
                    labelText="Bookmaker Id"
                    className="inspectorInputContainer"
                    onChange={this.onChangeBookmakerId}
                    value={this.state.bookmakerId}
                    inputClassName="inspectorInput"
                />
                <Button
                    onClick={this.loadLoInfo.bind(this)}
                    type={Button.types.LARGE_BLUE_SHADOW}
                >
                    Go
                </Button>
            </div>
        );
    }

    getTableRows(title, data) {
        const rows = [];

        rows.push(
            <tr>
                <th key="1" colSpan={2}>
                    {title}
                </th>
            </tr>
        );

        rows.push(
            <tr>
                <th>Attribute</th>
                <th>Value</th>
            </tr>
        );

        for (const attribute of Object.keys(data)) {
            rows.push(
                <tr>
                    <td>{attribute}</td>
                    <td key={attribute}>
                        {data[attribute] === null ? 'null' : data[attribute]}
                    </td>
                </tr>
            );
        }

        return rows;
    }

    getLoInfo() {
        if (this.state.isLoading) {
            return <BusySpinner />;
        }

        const generalInfoRows = this.getTableRows(
            'General',
            this.state.loInfo.match
        );
        const bookmakerInfoRows = this.getTableRows(
            'Bookmaker specific',
            this.state.loInfo.bookmakerInfo
        );

        return (
            <div className="loInfoTable">
                <div className="note">Showing data for {this.state.uri}</div>
                <table>{generalInfoRows}</table>
                <table>{bookmakerInfoRows}</table>
            </div>
        );
    }

    render() {
        return (
            <div className="inspector">
                {this.getForm()}
                {this.state.matchSelected && this.getLoInfo()}
            </div>
        );
    }
}

Inspector.propTypes = {
    defaultBookmakerId: PropTypes.number.isRequired,
    defaultUri: PropTypes.string,
};

const mapStateToProps = state => ({
    defaultBookmakerId: state.calendar.auth.userInfo.bookmakerId,
    defaultUri: state.inspector.defaultUri,
});

export default connect(mapStateToProps)(Inspector);
