import { PdsText } from '@podium-design-system/react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../../../utils/CalendarMoment';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import LiveStatusIcon from '../../../../../icons/LiveStatusIcon/LiveStatusIcon';
import SportIcon from '../../../../../icons/SportIcon/SportIcon';
import AddOnsDetail from '../AddOnsDetail/AddOnsDetail';
import { buildAvailability } from '../ContentEventInfo';
import Group from '../Group/Group';
import ProductDetail from '../ProductDetail/ProductDetail';
import './stageInfo.scss';

const buildEventName = match => {
    let eventName = match.ancestors[1].name;
    eventName += ` (${match.name})`;
    return (
        <Group>
            <div className="ui-600 event-name bold">{eventName}</div>
        </Group>
    );
};

function buildCoverage(event) {
    const coverageDetails = [
        <div className="coverage icon-label ui-400" key="venue">
            <FontIcon icon="" />
            {event.isVenue ? 'VENUE' : 'TV'}
        </div>,
    ];

    let liveOddsCoverage = 'no';
    let scoutCoverage = 'no';

    if (event.products.lo && event.products.lo.bookingStatusId !== 0) {
        liveOddsCoverage = 'yes';

        if (event.products.lo.extended) {
            liveOddsCoverage += ' - (extended)';
        }
    }

    if (event.products.ld && event.products.ld.bookingStatusId !== 0) {
        scoutCoverage = 'yes';

        if (event.products.ld.deeper) {
            scoutCoverage += ' - (deeper)';
        } else {
            scoutCoverage += ' - (basic)';
        }
    }

    coverageDetails.push(
        <div className="side-by-side" key="side-by-side">
            <div className="sub-group live-odds" key="lo">
                <div className="group-header ui-600 bold">Live Odds</div>
                <div
                    className={`icon-label ui-400 icon-${
                        liveOddsCoverage === 'no' ? 'red' : 'green'
                    }`}
                >
                    <FontIcon icon={liveOddsCoverage === 'no' ? '' : ''} />
                    <div className="capitalize-first">{liveOddsCoverage}</div>
                </div>
            </div>
            <div className="sub-group scout" key="scout">
                <div className="group-header ui-600 bold">Scout</div>
                <div
                    className={`icon-label ui-400 icon-${
                        scoutCoverage === 'no' ? 'red' : 'green'
                    }`}
                >
                    <FontIcon icon={scoutCoverage === 'no' ? '' : ''} />
                    <div className="capitalize-first">{scoutCoverage}</div>
                </div>
            </div>
        </div>
    );

    return coverageDetails;
}

function StageInfo(props) {
    const dateOfMatchMoment = moment.fromTimestamp(props.event.startDate);
    const [addOnsIconCount, setAddOnsIconCount] = useState(0);
    const addOnRef = useRef(null);

    useEffect(() => {
        if (addOnRef?.current) {
            const addOns =
                addOnRef.current.querySelectorAll('pds-icon-button').length;
            setAddOnsIconCount({ addOnsIconCount: addOns });
        }
    }, []);

    const liveStatusIcon = (
        <span className="livestatus">
            <LiveStatusIcon
                liveStatus={props.event.status}
                liveStatusText={props.event.statusText}
                access={props.access}
            />
        </span>
    );

    const time = moment.now() > dateOfMatchMoment ? 'past' : 'future';

    const sportName = props.sports[props.event.sportId]
        ? props.sports[props.event.sportId].name
        : 'Undefined';

    return (
        <div className="event-info">
            <Group className="sport-info">
                <div className="group-header icon-label ui-600">
                    <SportIcon sportId={props.event.sportId} />
                    {sportName}
                </div>
                <div className="event-uri display-200">{props.event.uri}</div>

                <div className="group-header ui-600 space-above">
                    {dateOfMatchMoment.toFormat('DDDD')}
                </div>
                <div className="icon-label ui-400">
                    <FontIcon icon="" />
                    {props.event.statusText}
                </div>
                <div className="time-livestatus">
                    <div className="icon-label ui-400">
                        <FontIcon icon="" />
                        {dateOfMatchMoment.toFormat('HH:mm')}
                    </div>
                    <div className={`icon-label ui-400 relative-time ${time}`}>
                        {/*TODO edit icon if time has been exceeded*/}
                        <FontIcon icon={time === 'past' ? '' : ''} />
                        {dateOfMatchMoment.toRelative()}
                    </div>
                    {liveStatusIcon}
                </div>
            </Group>
            {buildEventName(props.event)}
            <div className="sub-group">
                <div className="side-by-side">
                    <div className="icon-label ui-400">
                        <FontIcon icon="" />
                        {props.event.realCategoryName}
                    </div>
                    <div className="icon-label ui-400 tournament-holder">
                        <FontIcon icon="" />
                        {props.event.ancestors[2].name}
                    </div>
                </div>

                <div className="extra-info" key="extra-info">
                    {props.event.extra}
                </div>
                {props.event.var && (
                    <div className="extra-info">VAR available</div>
                )}
            </div>
            {props.isNewProductsUIEnabled && (
                <Group className="products">
                    <PdsText
                        type="textline"
                        fontSize={600}
                        fontWeight="strong"
                        color="neutral"
                        style={{ marginBottom: '10px', display: 'flex' }}
                    >
                        Products
                    </PdsText>
                    <div className="products-wrapper">
                        <ProductDetail event={props.event} />
                    </div>

                    {addOnsIconCount > 0 && (
                        <PdsText
                            type="textline"
                            fontSize={600}
                            fontWeight="strong"
                            color="neutral"
                            style={{
                                marginBottom: '10px',
                                display: 'flex',
                            }}
                        >
                            Add-ons
                        </PdsText>
                    )}
                    <div className="products-wrapper" ref={addOnRef}>
                        <AddOnsDetail event={props.event} />
                    </div>
                </Group>
            )}

            {buildAvailability(props.event)}
            {buildCoverage(props.event)}
        </div>
    );
}

StageInfo.propTypes = {
    event: PropTypes.object.isRequired,
    bookmakerInfo: PropTypes.object.isRequired,
    access: PropTypes.object.isRequired,
    sports: PropTypes.object.isRequired,
    isNewProductsUIEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        sports: state.calendar.sports,
        bookmakerInfo: state.calendar.auth.userInfo.bookmakerInfo,
        access: state.calendar.access,
        isNewProductsUIEnabled:
            state.viewport.selectedVersion === 'new-version' ||
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .new_products_ui,
    };
};

export default connect(mapStateToProps)(StageInfo);
