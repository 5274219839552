import Xhr from '../../utils/Xhr';
import changelogConstants from './constants';
import Alert from '../../components/Alert/Alert';
import { create as createNotification } from '../notification/actions';

export const fetchChangelog = () => dispatch => {
    Xhr.backendRequest('/changelog/all')
        .then(response =>
            dispatch({
                type: changelogConstants.FETCH,
                payload: response.response,
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const createChangelogEntry = changelogEntry => dispatch => {
    dispatch({
        type: changelogConstants.START_CREATION,
    });

    Xhr.backendRequest('/changelog/new', null, 'POST', changelogEntry)
        .then(response =>
            dispatch({
                type: changelogConstants.CREATE,
                payload: { ...response, ...changelogEntry },
            })
        )
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const updateChangelogEntry = changelogEntry => dispatch => {
    dispatch({
        type: changelogConstants.START_UPDATE,
        payload: changelogEntry.id,
    });

    Xhr.backendRequest('/changelog/update', null, 'POST', changelogEntry)
        .then(response => {
            if (!response.success) {
                throw new Error(
                    'An error occured while updating changelog entry.'
                );
            }

            dispatch({
                type: changelogConstants.UPDATE,
                payload: changelogEntry,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};

export const deleteChangelogEntry = id => dispatch => {
    Xhr.backendRequest(`/changelog/delete/${id}`, null, 'DELETE')
        .then(response => {
            if (!response.success) {
                throw new Error(
                    'An error occured while deleting changelog entry.'
                );
            }

            dispatch({
                type: changelogConstants.DELETE,
                payload: id,
            });
        })
        .catch(error => {
            createNotification(Alert.types.ERROR, error.message);
        });
};
