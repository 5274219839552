import {
    PdsIconButton,
    PdsLabel,
    PdsTooltip,
} from '@podium-design-system/react-components';
import {
    PdsBroadcastAntennaIcon,
    PdsDiamondIcon,
    PdsMonitorDesktopIcon,
} from '@podium-design-system/react-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const AddOns = ({ event }) => {
    const addOnRef = useRef(null);
    const [addOnsIconCount, setAddOnsIconCount] = useState(0);

    useEffect(() => {
        const count =
            addOnRef.current.querySelectorAll('pds-icon-button').length;
        setAddOnsIconCount(count);
    }, []);

    return (
        <div
            className={classNames('addOns', {
                hoverAddOns: addOnsIconCount > 1,
            })}
            ref={addOnRef}
        >
            {' '}
            {Object.keys(event.products).map(prod => {
                if (
                    prod === 'lo' &&
                    event.products['lo']?.addOns?.find(
                        addOn => addOn.name === 'LO LATENCY'
                    )
                ) {
                    return (
                        <PdsTooltip
                            key="lo-latency"
                            size="md"
                            arrow={true}
                            preferredPosition="top"
                            fixedPosition="top"
                            intensity="low"
                            surface="on-light"
                        >
                            <div
                                slot="text"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    display: 'flex',
                                }}
                            >
                                Latency
                            </div>
                            <PdsIconButton
                                size="xs"
                                rank="outline"
                                color="neutral"
                                opaqueBackground={true}
                            >
                                <PdsMonitorDesktopIcon slot="icon" />
                            </PdsIconButton>
                        </PdsTooltip>
                    );
                }
                if (
                    prod === 'lco' &&
                    event.products['lco']?.addOns?.find(
                        addOn =>
                            addOn.name === 'HIGH_QUALITY' &&
                            addOn.value === 'true'
                    )
                ) {
                    return (
                        <PdsTooltip
                            key="high-quality"
                            size="md"
                            arrow={true}
                            preferredPosition="top"
                            fixedPosition="top"
                            intensity="low"
                            surface="on-light"
                        >
                            <div
                                slot="text"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    display: 'flex',
                                }}
                            >
                                High Quality
                            </div>
                            <PdsIconButton
                                size="xs"
                                rank="outline"
                                color="neutral"
                                opaqueBackground={true}
                            >
                                <PdsDiamondIcon slot="icon" />
                            </PdsIconButton>
                        </PdsTooltip>
                    );
                }
                if (prod === 'lco-4sight' || prod === 'lco-as') {
                    return (
                        <PdsTooltip
                            key="aug-streaming"
                            size="md"
                            arrow={true}
                            preferredPosition="top"
                            fixedPosition="top"
                            intensity="low"
                            surface="on-light"
                        >
                            <div
                                slot="text"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    display: 'flex',
                                }}
                            >
                                4Sight
                            </div>
                            <PdsIconButton
                                size="xs"
                                rank="outline"
                                color="neutral"
                                opaqueBackground={true}
                            >
                                <PdsBroadcastAntennaIcon slot="icon" />
                            </PdsIconButton>
                        </PdsTooltip>
                    );
                }
                return null;
            })}
            {addOnsIconCount > 1 && (
                <PdsLabel
                    size="sm"
                    color="neutral"
                    intensity="low"
                    border={true}
                    padding="tight"
                    className="addOnCount"
                    style={{ '--border-radius': '8px' }}
                >
                    {addOnsIconCount}
                </PdsLabel>
            )}
        </div>
    );
};

AddOns.propTypes = {
    event: PropTypes.object.isRequired,
};

export default AddOns;
