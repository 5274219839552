import React from 'react';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_NAME,
} from '../../../../../../constants/shoppingBasket';
import { PdsLabel, PdsText } from '@podium-design-system/react-components';
import _ from 'underscore';

const ProductDetail = ({ event }) => {
    const bookingStatusId = [
        BOOKING_STATUS.BOOKED,
        BOOKING_STATUS.BOOKABLE,
        BOOKING_STATUS.BUYABLE,
    ];
    const groupedProdStatus = _.groupBy(event.products, 'bookingStatusId');
    return bookingStatusId.map(statusKey => {
        const productsGroup = groupedProdStatus[statusKey];
        return (
            <div key={statusKey}>
                <PdsLabel
                    size="md"
                    color={
                        statusKey === BOOKING_STATUS.BOOKED
                            ? 'positive'
                            : statusKey === BOOKING_STATUS.BOOKABLE
                            ? 'positive'
                            : 'attention'
                    }
                    intensity={
                        statusKey === BOOKING_STATUS.BOOKED ? 'high' : 'low'
                    }
                    border={true}
                    padding="tight"
                    surface="on-light"
                    fixedWidth="20px"
                    style={{ '--height': '20px' }}
                >
                    {productsGroup ? productsGroup.length : 0}
                </PdsLabel>
                <PdsText
                    type="interface"
                    fontSize={400}
                    style={{ marginLeft: '3px', display: 'inline-flex' }}
                >
                    {BOOKING_STATUS_NAME[statusKey] + ':'}
                </PdsText>
                <ul className="products-list">
                    {productsGroup?.map(prod => (
                        <li key={prod.type}>
                            <PdsText
                                type="interface"
                                fontSize={400}
                                style={{ '--text-transform': 'uppercase' }}
                                fontWeight="base"
                            >
                                {prod?.type}
                            </PdsText>
                        </li>
                    ))}
                </ul>
            </div>
        );
    });
};

export default ProductDetail;
