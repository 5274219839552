import React from 'react';
import ProductBookingStatusV2 from './ProductBookingStatusV2';
import ProductBookingStatus from './ProductBookingStatus';
import RecommendedStatus from './RecommendedStatus';
import PropTypes from 'prop-types';

const CommonProductBookingStatus = props => {
    return props.isNewProductsUIEnabled ? (
        <>
            <ProductBookingStatusV2
                event={props.event}
                inTimeLine={props.inTimeLine}
                isSelected={props.isSelected}
                isExtraSmall={props.isExtraSmall}
                isExtraExtraSmall={props.isExtraExtraSmall}
                isExpanded={props.isExpanded}
                recommenderSettings={props.recommenderSettings}
            />
            <RecommendedStatus
                event={props.event}
                recommenderSettings={props.recommenderSettings}
            />
        </>
    ) : (
        <ProductBookingStatus
            event={props.event}
            inTimeLine={props.inTimeLine}
            isSelected={props.isSelected}
            isExtraSmall={props.isExtraSmall}
            isExtraExtraSmall={props.isExtraExtraSmall}
            isExpanded={props.isExpanded}
            recommenderSettings={props.recommenderSettings}
        />
    );
};
CommonProductBookingStatus.propTypes = {
    event: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    inTimeLine: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isRecommended: PropTypes.bool,
    isExtraSmall: PropTypes.bool,
    isExtraExtraSmall: PropTypes.bool,
    bookmakerInfo: PropTypes.object.isRequired,
    recommenderSettings: PropTypes.object,
    isNewProductsUIEnabled: PropTypes.bool,
};

export default CommonProductBookingStatus;
