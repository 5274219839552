const views = {
    VIEWPORT_TYPE_LIST_CONDENSED: 'VIEWPORT_TYPE_LIST_CONDENSED',
    VIEWPORT_TYPE_TIMELINE_GADGET: 'VIEWPORT_TYPE_TIMELINE_GADGET',
    VIEWPORT_TYPE_BOOKING_HISTORY: 'VIEWPORT_TYPE_BOOKING_HISTORY',
    VIEWPORT_TYPE_LCO_BOOKING_HISTORY: 'VIEWPORT_TYPE_LCO_BOOKING_HISTORY',
    VIEWPORT_TYPE_LCR_BOOKING_HISTORY: 'VIEWPORT_TYPE_LCR_BOOKING_HISTORY',
    VIEWPORT_TYPE_LCT_BOOKING_HISTORY: 'VIEWPORT_TYPE_LCT_BOOKING_HISTORY',
    VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW:
        'VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW',
    VIEWPORT_TYPE_ADMIN: 'VIEWPORT_TYPE_ADMIN',
    VIEWPORT_TYPE_INSPECTOR: 'VIEWPORT_TYPE_INSPECTOR',
    VIEWPORT_TYPE_ERROR: 'VIEWPORT_TYPE_ERROR',
};

export default views;

const view = (name, isEventView = false) => {
    return {
        name,
        isEventView,
    };
};
export const viewData = {
    [views.VIEWPORT_TYPE_LIST_CONDENSED]: view('listCondensed', true),
    [views.VIEWPORT_TYPE_TIMELINE_GADGET]: view('schedule overview', true),
    [views.VIEWPORT_TYPE_BOOKING_HISTORY]: view('booking history'),
    [views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY]: view('LCO booking history'),
    [views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY]: view('LCR booking history'),
    [views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY]: view('LCT booking history'),
    [views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW]: view(
        'autobooking overview'
    ),
    [views.VIEWPORT_TYPE_ADMIN]: view('admin'),
    [views.VIEWPORT_TYPE_INSPECTOR]: view('inspector'),
    [views.VIEWPORT_TYPE_ERROR]: view('error'),
};
