export const AV_PRODUCT_ID = {
    LCO: 2,
    LCR: 1,
    LCT: 4,
};

export const AV_PRODUCT = {
    ID: {
        LCO: AV_PRODUCT_ID.LCO,
        LCR: AV_PRODUCT_ID.LCR,
        LCT: AV_PRODUCT_ID.LCT,
    },

    FULLNAME: {
        [AV_PRODUCT_ID.LCR]: 'Live Channel Retail',
        [AV_PRODUCT_ID.LCO]: 'Live Channel Online',
        [AV_PRODUCT_ID.LCT]: 'Live Channel Trading',
    },

    SHORTNAME: {
        [AV_PRODUCT_ID.LCO]: 'LCO',
        [AV_PRODUCT_ID.LCR]: 'LCR',
        [AV_PRODUCT_ID.LCT]: 'LCT',
    },

    KEY: {
        // These reflect the model-key (.streaming.lcx)
        [AV_PRODUCT_ID.LCO]: 'lco',
        [AV_PRODUCT_ID.LCR]: 'lcr',
        [AV_PRODUCT_ID.LCT]: 'lct',
    },

    PACKAGE_TYPE: {
        MSM: 1,
        FIXED: 2,
        OPTIONAL: 3,
    },

    STREAM_TYPE: {
        DASH_MANIFEST: 'dash-manifest',
        CMAF_DASH: 'cmaf-manifest',
        HLS: 'hls',
        HLS_MANIFEST: 'hls-manifest',
    },

    STREAM_STATUS: {
        ON_AIR_READY: 1,
        ON_AIR_BROADCAST: 2,
        ERROR: 3,
        DELIVERED: 4,
        APPROVED: 5,
    },
};

export const AV_BOOKING = {
    INVOICE_STATUS: {
        NON_INVOICEABLE: 2,
        PENDING: 0,
        PARTIAL: 3,
        INVOICEABLE: 1,
    },
};
