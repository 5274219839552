import { switchToView } from '../calendar/actions';
import actionConstants from './constants';

export const setCurrentView = view => dispatch => {
    dispatch({
        type: actionConstants.SET_CURRENT_VIEW,
        payload: {
            view,
        },
    });
    dispatch(switchToView(view));
};

export const setSelectedVersion = selectedVersion => dispatch => {
    dispatch({
        type: actionConstants.SET_SELECTED_VERSION,
        payload: {
            selectedVersion,
        },
    });
};
