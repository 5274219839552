import { PdsIconButton, PdsText } from '@podium-design-system/react-components';
import {
    PdsBroadcastAntennaIcon,
    PdsDiamondIcon,
    PdsMonitorDesktopIcon,
} from '@podium-design-system/react-icons';
import React from 'react';

const AddOnsDetail = ({ event }) => {
    return Object.keys(event.products).map(prod => {
        if (
            prod === 'lo' &&
            event.products['lo']?.addOns?.find(
                addOn => addOn.name === 'LO LATENCY'
            )
        ) {
            return (
                <div key="latency">
                    <PdsIconButton
                        size="xs"
                        rank="outline"
                        color="neutral"
                        opaqueBackground={true}
                    >
                        <PdsMonitorDesktopIcon slot="icon" />
                    </PdsIconButton>
                    <PdsText
                        type="interface"
                        fontSize={400}
                        style={{ marginLeft: '5px', display: 'inline-flex' }}
                    >
                        Latency
                    </PdsText>
                </div>
            );
        }
        if (
            prod === 'lco' &&
            event.products['lco']?.addOns?.find(
                addOn => addOn.name === 'HIGH_QUALITY' && addOn.value
            )
        ) {
            return (
                <div key="high-quality">
                    <PdsIconButton
                        size="xs"
                        rank="outline"
                        color="neutral"
                        opaqueBackground={true}
                    >
                        <PdsDiamondIcon slot="icon" />
                    </PdsIconButton>
                    <PdsText
                        type="interface"
                        fontSize={400}
                        style={{ marginLeft: '5px', display: 'inline-flex' }}
                    >
                        High Quality
                    </PdsText>
                </div>
            );
        }
        if (prod === 'lco-4sight' || prod === 'lco-as') {
            return (
                <div key="4sight">
                    <PdsIconButton
                        size="xs"
                        rank="outline"
                        color="neutral"
                        opaqueBackground={true}
                    >
                        <PdsBroadcastAntennaIcon slot="icon" />
                    </PdsIconButton>
                    <PdsText
                        type="interface"
                        fontSize={400}
                        style={{ marginLeft: '5px', display: 'inline-flex' }}
                    >
                        4Sight
                    </PdsText>
                </div>
            );
        }
        return null;
    });
};

export default AddOnsDetail;
