import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from '../../../../../constants/Constants';
import FreeTextSearch from '../../../../formInput/FreeTextSearch/FreeTextSearch.jsx';
import ProductPicker from '../../../../formInput/ProductPicker/ProductPicker.jsx';
import FilterCheckboxList from './FilterCheckboxList/FilterCheckboxList.jsx';
import Button from '../../../../formInput/Button/Button';
import ActionRow from './ActionRow/ActionRow';
import { addDialog } from '../../../../../stores/dialog/actions';
import { dialogTypes } from '../../../../../stores/dialog/constants';
import ContentBox from '../../../../ContentBox/ContentBox';
import ContentBoxTitle from '../../../../ContentBoxTitle/ContentBoxTitle';
import {
    toggleHelpBalloon,
    setFreeTextSearchPhrase,
    recalculateFilteredGroups,
    setProductFilter,
    setNcaaFilter,
} from '../../../../../stores/calendar/actions';
import './contentFilters.scss';
import FilterNcaaFilters from './NcaaFilters/FilterNcaaFilters';

class ContentFilters extends React.Component {
    actionTypes = {
        SAVE_FILTER: 0,
        LOAD_FILTER: 1,
        EXPORT: 2,
    };

    buildHelpManualLink(backendSettings) {
        if (
            backendSettings &&
            backendSettings.betradar &&
            backendSettings.betradar.linkToManual
        ) {
            return (
                <Button
                    type={Button.types.LARGE_BLUE_BORDER}
                    className="help-button"
                    icon=""
                    onClick={() => {
                        window.open(
                            backendSettings.betradar.linkToManual,
                            '_blank'
                        );
                    }}
                >
                    Manual (PDF)
                </Button>
            );
        }
        return '';
    }

    buidChangelogsButton() {
        return (
            <Button
                type={Button.types.LARGE_BLUE_BORDER}
                className={'help-button'}
                onClick={() => {
                    this.props.addDialog(dialogTypes.CHANGELOGS);
                }}
            >
                Changelogs
            </Button>
        );
    }

    buildLegendButton() {
        return (
            <Button
                type={Button.types.LARGE_BLUE_BORDER}
                className={'help-button'}
                icon={''}
                onClick={() => {
                    this.props.addDialog(dialogTypes.LEGEND);
                }}
            >
                Legend
            </Button>
        );
    }

    openHelpBalloon = () => {
        this.props.toggleHelpBalloon(
            true,
            constants.BALLOON_TYPE_FILTERS_HELP,
            null,
            'leftcolumn'
        );
    };

    actionRowClicked = id => {
        switch (id) {
            case this.actionTypes.SAVE_FILTER:
                this.props.addDialog(dialogTypes.SAVE_FILTER);
                break;
            case this.actionTypes.LOAD_FILTER:
                this.props.addDialog(dialogTypes.LOAD_FILTER);
                break;
            case this.actionTypes.EXPORT:
                this.props.addDialog(dialogTypes.EXPORT);
                break;
            default:
                console.error(`Invalid id: ${id}`);
        }
    };

    onSaveSearchPhrase = phrase => {
        this.props.setFreeTextSearchPhrase([...new Set(phrase)]);
        this.props.recalculateFilteredGroups(this.props.currentView);
    };

    onSaveProducts = products => {
        this.props.setProductFilter(products);
        this.props.recalculateFilteredGroups(this.props.currentView);
    };

    onSaveNcaaFilter = filter => {
        this.props.setNcaaFilter(filter);
        this.props.recalculateFilteredGroups(this.props.currentView);
    };

    render() {
        const filters = [];

        filters.push(
            <FreeTextSearch
                key="freetext"
                onSavePhrase={this.onSaveSearchPhrase}
                savedPhrase={this.props.freeTextSearchPhrase}
                contentBox={true}
            />,
            <ProductPicker
                key="product"
                label="Products"
                products={['lo', 'ld', 'bp', 'lco', 'lcr', 'lct']}
                selectedProducts={this.props.selectedProducts}
                onChange={this.onSaveProducts}
            />,
            <FilterCheckboxList
                key="sports"
                title="Sports"
                groupId={constants.FILTER_GROUP_SPORTS}
                showSportIcons={true}
                showNCheckboxesWhileCollapsed={8}
                collapsedByDefault={false}
            />,
            <FilterCheckboxList
                key="matches"
                title="Matches"
                showBookedCounts={false}
                groupId={constants.FILTER_GROUP_MATCHES}
                collapsedByDefault={false}
            />
        );

        if ((this.props.bookmakerInfo.segment.segmentId || 1) !== 1) {
            filters.push(
                <FilterCheckboxList
                    key="mvp_product"
                    title="Matches - LO/LD package type"
                    groupId={constants.FILTER_GROUP_SEGMENT_TYPE}
                />
            );
        }

        filters.push(
            <FilterCheckboxList
                key="covered_from"
                title="Covered From"
                groupId={constants.FILTER_GROUP_COVERED_FROM}
            />,
            <FilterCheckboxList
                key="covered_with"
                title="Covered With"
                groupId={constants.FILTER_GROUP_COVERED_WITH}
            />,
            <FilterCheckboxList
                key="age category"
                title="Age Groups"
                groupId={constants.FILTER_GROUP_AGE_GROUPS}
            />,
            <FilterCheckboxList
                key="sport type"
                title="Sport Type"
                groupId={constants.FILTER_GROUP_SPORT_TYPE}
            />,
            <FilterNcaaFilters
                key="ncaa"
                title="NCAA"
                onChange={this.onSaveNcaaFilter}
                ncaaFilter={this.props.ncaaFilter}
            />
        );

        const options = (
            <ContentBox key="options">
                <ContentBoxTitle>Options</ContentBoxTitle>
                <ActionRow
                    buttons={[
                        {
                            text: 'Save Filters',
                            id: this.actionTypes.SAVE_FILTER,
                        },
                        {
                            text: 'Load Filters',
                            id: this.actionTypes.LOAD_FILTER,
                        },
                        this.props.showExportMatchesBtn && {
                            text: 'Export Matches',
                            id: this.actionTypes.EXPORT,
                            icon: '',
                        },
                    ]}
                    buttonColor={Button.types.LARGE_BLUE_BORDER}
                    onClick={this.actionRowClicked}
                />
            </ContentBox>
        );
        const help = (
            <ContentBox key="help">
                <ContentBoxTitle>Help</ContentBoxTitle>
                <p>
                    <span className="dummy-link" onClick={this.openHelpBalloon}>
                        Learn more
                    </span>{' '}
                    about the filters and your current settings.
                </p>
                <div className="help-buttons">
                    {this.buildHelpManualLink(this.props.backendSettings)}
                    {this.buidChangelogsButton()}
                    {this.buildLegendButton()}
                </div>
            </ContentBox>
        );
        return (
            <Fragment>
                <div className="content-filters">{filters}</div>
                <div className="filters-footer">
                    {options}
                    {help}
                </div>
            </Fragment>
        );
    }
}

ContentFilters.propTypes = {
    freeTextSearchPhrase: PropTypes.array.isRequired,
    backendSettings: PropTypes.object,
    toggleHelpBalloon: PropTypes.func.isRequired,
    setFreeTextSearchPhrase: PropTypes.func.isRequired,
    recalculateFilteredGroups: PropTypes.func.isRequired,
    setProductFilter: PropTypes.func.isRequired,
    setNcaaFilter: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
    bookmakerInfo: PropTypes.object,
    selectedProducts: PropTypes.array,
    ncaaFilter: PropTypes.object,
    showExportMatchesBtn: PropTypes.bool,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    freeTextSearchPhrase: state.calendar.freeTextSearchPhrase,
    backendSettings: state.calendar.backendSettings,
    bookmakerInfo: state.calendar.auth.userInfo.bookmakerInfo,
    selectedProducts: state.calendar.selectedProducts,
    ncaaFilter: state.calendar.ncaaFilter,
    showExportMatchesBtn:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .show_export_matches_btn,
});

const mapDispatchToProps = {
    toggleHelpBalloon,
    setFreeTextSearchPhrase,
    recalculateFilteredGroups,
    setProductFilter,
    setNcaaFilter,
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentFilters);
