import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextInput from '../TextInput/TextInput';
import MultiWordInput from './MultiWordInput/MultiWordInput';
import ContentBox from '../../ContentBox/ContentBox';
import ContentBoxTitle from '../../ContentBoxTitle/ContentBoxTitle';

class FreeTextSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { freeText: '' };
    }

    _saveSearchPhrase(phrase) {
        if (this.props.onSavePhrase) {
            this.props.onSavePhrase(phrase);
        }
    }

    _labelClickHandler() {
        this.setState({ freeText: '' });
        this._saveSearchPhrase(''); // = reset search
    }

    _keyDownHandler(e) {
        if (e.keyCode === 13) {
            // ENTER
            this._saveSearchPhrase(this._getCurrentText());
        }
    }

    _renderTextField() {
        return (
            <TextInput
                onChange={this._onChange.bind(this)}
                value={this._getCurrentText()}
                onKeyDown={this._keyDownHandler.bind(this)}
            />
        );
    }

    _getCurrentText() {
        if (this.props.value !== null) {
            return this.props.value;
        }
        return this.state.freeText;
    }

    _onChange(text) {
        if (this.props.onChange) {
            this.props.onChange(text);
        }
        this.setState({ freeText: text });
    }

    render() {
        let content = '';
        if (Array.isArray(this.props.savedPhrase)) {
            content = (
                <MultiWordInput
                    words={this.props.savedPhrase}
                    onChange={this.props.onSavePhrase}
                />
            );
        } else {
            content = this._renderTextField();
        }
        return (
            <ContentBox>
                <ContentBoxTitle>Search</ContentBoxTitle>
                {content}
            </ContentBox>
        );
    }
}

FreeTextSearch.propTypes = {
    onChange: PropTypes.func,
    onSavePhrase: PropTypes.func,
    savedPhrase: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired,
    contentBox: PropTypes.bool,
    value: PropTypes.string,
};

FreeTextSearch.defaultProps = {
    onChange: null,
    onSavePhrase: null,
    savedPhrase: '',
    contentBox: false,
    value: null,
};

export default FreeTextSearch;
