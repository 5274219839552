import { adapterIds } from '../../constants/shoppingBasket';
import { AV_PRODUCT } from '../../constants/audioVisual';

export default calendarStoreState => {
    const bookmakerInfo = calendarStoreState.auth.userInfo.bookmakerInfo;
    const enabledApis = calendarStoreState.backendSettings.enabledApis;

    return Object.values(adapterIds).filter(adapterId => {
        if (
            adapterId === adapterIds.BETPAL &&
            (!bookmakerInfo.canBookBetpal || !enabledApis.betpal)
        ) {
            return false;
        } else if (
            adapterId === adapterIds.LCO &&
            (!bookmakerInfo.audioVisual.products.includes(AV_PRODUCT.ID.LCO) ||
                !enabledApis.audioVisual)
        ) {
            return false;
        } else if (
            adapterId === adapterIds.LCR &&
            (!bookmakerInfo.audioVisual.products.includes(AV_PRODUCT.ID.LCR) ||
                !enabledApis.audioVisual)
        ) {
            return false;
        } else if (
            adapterId === adapterIds.LCT &&
            (!bookmakerInfo.audioVisual.products.includes(AV_PRODUCT.ID.LCT) ||
                !enabledApis.audioVisual)
        ) {
            return false;
        }

        return true;
    });
};
