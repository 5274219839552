import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import getGroupedEvents from '../../../../../selectors/getGroupedEvents';
import {
    recalculateFilteredGroups,
    sortRecomendations,
} from '../../../../../stores/calendar/actions';
import moment from '../../../../../utils/CalendarMoment';
import Scroller from '../../../../../utils/Scroller';
import StorageLocal from '../../../../../utils/StorageLocal.js';
import EventContextMenu from '../../../../Event/EventContextMenu';
import EventMatchViewTrigger from '../../../../Event/EventMatchViewTrigger';
import EventSelectable from '../../../../Event/EventSelectable';
import EventSelector from '../../../../Event/EventSelector';
import FilterEventMessage from '../../../../FilterEventMessage/FilterEventMessage.jsx';
import { productText } from '../../../../formInput/ProductPicker/ProductPicker';
import './ListCondensed.scss';
import SortIcon from './SortIcon.jsx';

let scrollTimer = null,
    previousScrollTop = 0;

class ListCondensed extends React.Component {
    constructor(props) {
        super(props);

        this.viewPortRef = React.createRef();

        this.state = {
            scrollPosition: 0,
            isSortEnabled: false,
            sortingOrder: null,
            timeSortingOrder: null,
        };
    }

    componentDidMount() {
        let mmNow = moment.now().startOf('hour');
        let isToday = moment
            .fromTimestamp(this.props.momentSelectedDay)
            .hasSame(mmNow, 'day');
        if (isToday)
            Scroller.updateScrollPos(this.props.currentView, mmNow.c.hour);
    }

    componentWillUnmount() {
        this._clearScrollTimer();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedPriority !== this.props.selectedPriority &&
            this.state.isSortEnabled
        ) {
            this.sortRecommendations(this.state.sortingOrder);
        }
    }

    _clearScrollTimer = () => {
        if (scrollTimer) {
            clearTimeout(scrollTimer);
        }
    };

    sortRecommendations = sortingOrder => {
        this.setState(() => ({
            isSortEnabled: true,
            sortingOrder,
        }));
        this.props.sortRecomendations(sortingOrder);
    };

    sortEventsBasedOnTime = sortingOrder => {
        this.setState(() => ({
            isSortEnabled: false,
            timeSortingOrder: sortingOrder,
        }));
        this.props.recalculateFilteredGroups(
            this.props.currentView,
            sortingOrder
        );
    };

    _onScroll = () => {
        this._clearScrollTimer();

        const nextScrollTop = this.viewPortRef.current.scrollTop;
        if (nextScrollTop !== previousScrollTop) {
            previousScrollTop = nextScrollTop;
        }

        scrollTimer = setTimeout(() => {
            Scroller.updateScrollPos(this.props.currentView);

            this.setState({ scrollPosition: nextScrollTop });
        }, 100);
    };

    renderEvents = (events, visibleEvents, content, eventsArr) => {
        for (const i in events) {
            if (this.props.events.hasOwnProperty(i)) {
                const event = events[i];
                visibleEvents.push(event.uri);
                content.push(
                    <EventMatchViewTrigger key={event.uri} uri={event.uri}>
                        <EventSelectable
                            event={event}
                            isCondensed
                            width="100%"
                        />
                    </EventMatchViewTrigger>
                );
            }
        }
    };

    render() {
        const content = [],
            mmNow = moment.now().startOf('hour');
        const events = [];
        const storageLocal = new StorageLocal();
        storageLocal.setItem(
            'isStreamingQuarkusEnabled',
            this.props.isStreamingQuarkusEnabled
        );

        let isToday = moment
                .fromTimestamp(this.props.momentSelectedDay)
                .hasSame(mmNow, 'day'),
            hasHighlightedTab = false,
            visibleEvents = [];

        let headers = this.props.isNewProductsUIEnabled
            ? ['Sport', 'Time', 'Status', 'Fixture', 'Details', 'Products']
            : [
                  'Sport',
                  'Time',
                  'Status',
                  'Fixture',
                  'Details',
                  'LO',
                  'LD',
                  'BP',
                  'LCO',
                  'LCR',
                  'LCT',
              ];

        content.push(
            <div
                className={`event-condensed-row top-row ${
                    this.props.isNewProductsUIEnabled ? 'products-row' : ''
                }`}
                key="top-row"
            >
                {headers.map(header => (
                    <div
                        className={`header ${header.toLowerCase()} table-400`}
                        title={productText[header]}
                        key={`header_${header}`}
                    >
                        {header}
                        {header === 'Time' && (
                            <SortIcon
                                sortingOrder={this.state.timeSortingOrder}
                                onSortClick={sortOrder =>
                                    this.sortEventsBasedOnTime(sortOrder)
                                }
                            />
                        )}
                    </div>
                ))}
                {this.props.showRecommended && (
                    <div className="options">
                        <SortIcon
                            sortingOrder={this.state.sortingOrder}
                            onSortClick={sortOrder =>
                                this.sortRecommendations(sortOrder)
                            }
                        />
                    </div>
                )}
            </div>
        );
        if (this.state.isSortEnabled) {
            this.renderEvents(
                this.props.events,
                visibleEvents,
                content,
                events
            );
        } else {
            const groups =
                this.state.timeSortingOrder === 'desc'
                    ? Object.entries(this.props.groups).reverse()
                    : Object.entries(this.props.groups);
            for (const [k, value] of groups) {
                const group = value;
                const tsGroup = k;
                const mmGroup = moment.fromTimestamp(tsGroup);
                const timeheaderText = mmGroup.toFormat('HH:mm');
                let tabClassName = 'tab';

                if (
                    isToday &&
                    !hasHighlightedTab &&
                    mmGroup.hasSame(mmNow, 'hour')
                ) {
                    tabClassName += ' highlight';
                    hasHighlightedTab = true;
                }

                content.push(
                    <div
                        className={`event-condensed-row time-row ${
                            tabClassName +
                            ' ' +
                            this.props.isNewProductsUIEnabled
                                ? 'products-row'
                                : ''
                        }`}
                        key={'timeHeader_' + k}
                    >
                        <div className="time textline-500">
                            {timeheaderText}
                        </div>
                    </div>
                );

                this.renderEvents(group.events, visibleEvents, content, events);
            }
        }

        return (
            <div
                className="viewport-list"
                ref={this.viewPortRef}
                onScroll={this._onScroll}
            >
                <EventSelector
                    scrollContainer=".viewport-list"
                    eventsAreCondensed
                >
                    {content}
                    <EventContextMenu />
                    <FilterEventMessage visibleEvents={visibleEvents} />
                </EventSelector>
            </div>
        );
    }
}

ListCondensed.propTypes = {
    currentView: PropTypes.string.isRequired,
    momentSelectedDay: PropTypes.any.isRequired,
    groups: PropTypes.object.isRequired,
    isNewProductsUIEnabled: PropTypes.bool,
    sortRecomendations: PropTypes.func,
    events: PropTypes.array,
    recalculateFilteredGroups: PropTypes.func.isRequired,
    showRecommended: PropTypes.bool,
    selectedPriority: PropTypes.string,
    isStreamingQuarkusEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    momentSelectedDay: state.calendar.selectedDayTimestamp,
    groups: getGroupedEvents(state),
    events: state.calendar.events,
    isNewProductsUIEnabled:
        state.viewport.selectedVersion === 'new-version' ||
        !!state.calendar.auth.userInfo.bookmakerInfo.features.new_products_ui,
    showRecommended: !!state.calendar.recommenderSettings.showRecommended,
    selectedPriority: state.calendar.recommenderSettings.selectedPriority,
    isStreamingQuarkusEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_fullstream,
});
const mapDispatchToProps = { sortRecomendations, recalculateFilteredGroups };

export default connect(mapStateToProps, mapDispatchToProps)(ListCondensed);
