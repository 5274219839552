import { adapterIds, adapterLcProducts } from '../constants/shoppingBasket';
import moment from '../utils/CalendarMoment';

export const isConsideredBooked = (products, adapterId) => {
    switch (adapterId) {
        case adapterIds.LIVEODDS:
            return products.hasOwnProperty('lo') && products.lo.isBooked;
        case adapterIds.LCO:
            return (
                products.hasOwnProperty('lco') &&
                products.lco.isBooked &&
                (!products.hasOwnProperty('lco-4sight') ||
                    !products.hasOwnProperty('lco-as'))
            );
        case adapterIds.LCR:
            return (
                products.hasOwnProperty('lcr') &&
                products.lcr.isBooked &&
                (!products.hasOwnProperty('lcr-4sight') ||
                    !products.hasOwnProperty('lcr-as'))
            );
        case adapterIds.LCT:
            return (
                products.hasOwnProperty('lct') &&
                products.lct.isBooked &&
                (!products.hasOwnProperty('lct-4sight') ||
                    !products.hasOwnProperty('lct-as'))
            );
        case adapterIds.BETPAL:
            return products.hasOwnProperty('bp') && products.bp.isBooked;
        default:
            return false;
    }
};

export const createLabelForAugStreaming = (products, adapterId) => {
    const variant = Object.values(products).filter(
        product =>
            product.type.includes(`${adapterLcProducts[adapterId]}-4sight`) ||
            product.type.includes(`${adapterLcProducts[adapterId]}-as`)
    );
    const bookingStatus = [
        products[adapterLcProducts[adapterId]].bookingStatusId,
        variant[0].bookingStatusId,
    ].join('');
    switch (bookingStatus) {
        case '21':
            return `Std. Feed - Booked Aug. Streaming - Bookable`;
        case '22':
            return 'Booked';
        case '12':
            return `Std. Feed - Bookable Aug. Streaming - Booked`;
        case '23':
            return `Std. Feed - Booked Aug. Streaming - Buyable`;
        case '13':
            return `Std. Feed - Bookable Aug. Streaming - Buyable`;
        default:
            return false;
    }
};

export const hasProduct = (products, adapterId) => {
    switch (adapterId) {
        case adapterIds.LIVEODDS:
            return (
                products.hasOwnProperty('lo') || products.hasOwnProperty('ld')
            );
        case adapterIds.LCO:
            return products.hasOwnProperty('lco');
        case adapterIds.LCR:
            return products.hasOwnProperty('lcr');
        case adapterIds.LCT:
            return products.hasOwnProperty('lct');
        case adapterIds.BETPAL:
            return (
                products.hasOwnProperty('bp') || products.hasOwnProperty('lo')
            );
        default:
            return false;
    }
};

export const tooLateToBook = event => {
    // https://jira.sportradar.ag/browse/LOW-2664
    // After 60 days, they cannot book any more.
    return (
        moment.now().diff(moment.fromTimestamp(event.startDate), ['days'])
            .days > 60
    );
};
