import settings from '../../utils/DefaultSettings';
import Xhr from '../../utils/Xhr';
import actionConstants from './constants';
import Alert from '../../components/Alert/Alert';
import { create as createNotification } from '../notification/actions';
import loggerConstants from '../logger/constants';
import { downloadFile } from '../../utils/ExportUtils';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

export const loadAllAutobookings = () => (dispatch, getState) => {
    dispatch({ type: actionConstants.LOAD_AUTOBOOKINGS });
    const loadAllAutobookingsReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .autobook_group && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(settings.autobooking.getAllUri)
            : Xhr.backendRequest(settings.autobooking.getAllUri);
    loadAllAutobookingsReq.then(
        response => {
            const uri = settings.autobooking.getAllUri;
            dispatch({
                type: actionConstants.LOAD_AUTOBOOKINGS_SUCCESS,
                payload: { autobookings: response.response || response },
            });
            dispatch({
                type: loggerConstants.FETCH_LOGS_SUCCESS,
                payload: {
                    debug: response.debug,
                    uri,
                },
            });
        },
        error => {
            dispatch({
                type: actionConstants.LOAD_AUTOBOOKINGS_FAIL,
                payload: { error },
            });
        }
    );
};

export const saveAutobooking =
    (autobooking, postData) => (dispatch, getState) => {
        let uri = settings.autobooking.saveUri;
        let saveQuarkusUri = settings.autobooking.saveQuarkusUri;
        const hasId = parseInt(autobooking.id, 10) > 0;

        if (hasId) {
            uri += '/' + autobooking.id;
            saveQuarkusUri += '/' + autobooking.id;
        }
        const saveAutobookingReq =
            getState().calendar.auth.userInfo.bookmakerInfo.features
                .autobook_group && isMicrofrontend
                ? Xhr.backendRequestLbcQuarkus(
                      saveQuarkusUri,
                      null,
                      hasId ? 'PUT' : 'POST',
                      postData
                  )
                : Xhr.backendRequest(uri, null, 'POST', postData);

        saveAutobookingReq.then(
            response => {
                if (!hasId) {
                    autobooking.id = parseInt(response.insertId, 10);
                }

                dispatch({
                    type: actionConstants.AUTOBOOKING_SAVED,
                    payload: { autobooking },
                });
                dispatch(
                    createNotification(
                        Alert.types.SUCCESS,
                        'Autobooking rule ' + (hasId ? 'updated' : 'saved'),
                        ''
                    )
                );
            },
            error => {
                dispatch({
                    type: actionConstants.SAVE_FAILED,
                    payload: { error },
                });
            }
        );
    };

export const deleteAutobooking = ids => (dispatch, getState) => {
    const uri = settings.deleteAutobookingRuleUri;
    const deleteAutobookingReq =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .autobook_group && isMicrofrontend
            ? Xhr.backendRequestLbcQuarkus(uri, null, 'POST', { ids })
            : Xhr.backendRequest(uri, null, 'POST', { ids });
    deleteAutobookingReq.then(
        () => {
            dispatch({
                type: actionConstants.AUTOBOOKING_DELETED,
                payload: { ids },
            });
            dispatch(
                createNotification(
                    Alert.types.SUCCESS,
                    'Autobooking rules deleted',
                    ''
                )
            );
        },
        error => {
            dispatch({
                type: actionConstants.DELETE_FAILED,
                payload: { error },
            });
        }
    );
};

export const exportAutobookingRules = filter => (dispatch, getState) => {
    const uri = settings.exportAutobookingRuleUri;
    const enableQuarkusApi =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .autobook_group && isMicrofrontend;

    Xhr.exportFileRequest(uri, filter, 'GET', enableQuarkusApi).then(
        response => {
            let filename = `Export-autobooking-rules-BM_${filter.bmId}.csv`;
            let contentType = 'text/csv';
            downloadFile(filename, response, contentType);
            dispatch(
                createNotification(
                    Alert.types.SUCCESS,
                    'Autobooking rules exported',
                    ''
                )
            );
        },
        error => {
            console.error(error);
            dispatch(
                createNotification(
                    Alert.types.ERROR,
                    'Autobooking rules export failed',
                    ''
                )
            );
        }
    );
};

export const importAutobookingRulesFile = file => (dispatch, getState) => {
    const uri = settings.importAutobookingRuleUri;
    const enableQuarkusApi =
        getState().calendar.auth.userInfo.bookmakerInfo.features
            .autobook_group && isMicrofrontend;

    Xhr.uploadImportFile(uri, file, enableQuarkusApi).then(
        response => {
            dispatch({
                type: actionConstants.AUTOBOOKING_RULES_IMPORT,
                payload: { response },
            });
        },
        error => {
            console.error(error);
            dispatch(
                createNotification(
                    Alert.types.ERROR,
                    'Autobooking rules import failed',
                    ''
                )
            );
        }
    );
};

export const importAutobookingRules =
    (file, tmpFileName) => (dispatch, getState) => {
        console.log(tmpFileName);
        const importAutobookingRulesReq =
            getState().calendar.auth.userInfo.bookmakerInfo.features
                .autobook_group && isMicrofrontend
                ? Xhr.uploadImportFile(
                      `/autobook/import/execute?tmpFile=${tmpFileName}`,
                      file,
                      true
                  )
                : Xhr.backendRequest(
                      `/autobook/import/execute?tmpFile=${tmpFileName}`,
                      null,
                      'POST',
                      null
                  );
        importAutobookingRulesReq.then(
            response => {
                console.log(response);
                dispatch({
                    type: actionConstants.AUTOBOOKING_RULES_IMPORT_SUCCESS,
                    payload: { response },
                });
                dispatch(
                    createNotification(
                        Alert.types.SUCCESS,
                        'Autobooking rules imported',
                        ''
                    )
                );
            },
            error => {
                dispatch(
                    createNotification(
                        Alert.types.ERROR,
                        'Autobooking rules import failed',
                        ''
                    )
                );
            }
        );
    };

export const updateFilter = filters => ({
    type: actionConstants.UPDATE_FILTER,
    payload: { filters },
});

export const setFilters = filters => ({
    type: actionConstants.SET_FILTERS,
    payload: { filters },
});

export const toggleFilterSelected = filter => ({
    type: actionConstants.TOGGLE_FILTER_SELECTION,
    payload: { filter },
});

export const addFiltersSelected = filters => ({
    type: actionConstants.ADD_FILTERS_SELECTED,
    payload: { filters },
});

export const setFiltersToAllRelevant = () => ({
    type: actionConstants.SET_FILTERS_SELECTED_TO_RELEVANT,
});

export const toggleDisabledFilter = filter => ({
    type: actionConstants.TOGGLE_FILTER_DISABLED,
    payload: { filter },
});

export const setFilterOrder = order => ({
    type: actionConstants.SET_FILTER_ORDER,
    payload: { order },
});

export const resetFilters = () => ({
    type: actionConstants.RESET_FILTERS,
});

export const resetActiveFilters = () => ({
    type: actionConstants.RESET_ACTIVE_FILTERS,
});

export const activateFilters = () => ({
    type: actionConstants.ACTIVATE_FILTERS,
});

export const setEditId = editId => ({
    type: actionConstants.SET_EDIT_ID,
    payload: { editId },
});

const parseOptions = (response, sortValues, defaultValue) => {
    const toReturn = [];
    const actualResponse = response.response || response;

    if (actualResponse.teams) {
        for (const key in actualResponse.teams) {
            if (actualResponse.teams.hasOwnProperty(key)) {
                toReturn.push({
                    value: key,
                    label: actualResponse.teams[key],
                    title: actualResponse.teams[key],
                });
            }
        }
    } else {
        for (const key in actualResponse) {
            if (actualResponse.hasOwnProperty(key)) {
                toReturn.push({
                    value: key,
                    label: actualResponse[key].name,
                    title: actualResponse[key].name,
                });
            }
        }
    }

    if (sortValues) {
        let labelA, labelB;
        toReturn.sort((a, b) => {
            labelA = a.label.toUpperCase();
            labelB = b.label.toUpperCase();
            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        });
    }

    toReturn.unshift(defaultValue);

    return toReturn;
};

export const fetchOptions =
    (
        remoteDataUri,
        optionName,
        parentKey,
        parentValue,
        defaultValue,
        sortValues
    ) =>
    (dispatch, getState) => {
        if (remoteDataUri.endsWith('-1') || remoteDataUri.endsWith('/0')) {
            dispatch({
                type: actionConstants.LOAD_OPTIONS_SUCCESS,
                payload: { options: null, optionName },
            });
        } else {
            dispatch({
                type: actionConstants.LOAD_OPTIONS,
                payload: { optionName },
            });
            const fetchOptionsReq =
                getState().calendar.auth.userInfo.bookmakerInfo.features
                    .autobook_group &&
                isMicrofrontend &&
                !remoteDataUri.includes('tournament-info')
                    ? Xhr.backendRequestLbcQuarkus(remoteDataUri)
                    : Xhr.backendRequest(remoteDataUri);
            fetchOptionsReq.then(
                response => {
                    const options = parseOptions(
                        response,
                        sortValues,
                        defaultValue
                    );
                    dispatch({
                        type: actionConstants.LOAD_OPTIONS_SUCCESS,
                        payload: {
                            options,
                            optionName,
                            parentKey,
                            parentValue,
                        },
                    });
                },
                error => {
                    console.error(error);
                    dispatch({
                        type: actionConstants.LOAD_OPTIONS_FAIL,
                    });
                }
            );
        }
    };

export const loadFilterSet = filterSetName => ({
    type: actionConstants.LOAD_FILTER_SET,
    payload: { filterSetName },
});

export const setAutobookingType = selectedAutobookingType => ({
    type: actionConstants.SET_AUTOBOOKING_TYPE,
    payload: { selectedAutobookingType },
});

export const setAutobookingFormInitialDates = () => ({
    type: actionConstants.SET_INITIAL_DATES,
});
