import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import constants from '../../../../constants/Constants';
import { AV_PRODUCT } from '../../../../constants/audioVisual';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_NAME,
} from '../../../../constants/shoppingBasket';
import {
    addEvents,
    removeEvent,
} from '../../../../stores/shoppingBasket/actions';
import { eventCanBeAddedToBasket } from '../../../../utils/EventUtils';
import Xhr from '../../../../utils/Xhr';
import { isSubProductOptionalContent } from '../../../../utils/audioVisualUtils';
import Alert from '../../../Alert/Alert';
import BusySpinner from '../../../BusySpinner/BusySpinner';
import Tabs from '../../../Tabs/Tabs';
import Button from '../../../formInput/Button/Button';
import MatchQuickInfo from '../MatchQuickInfo/MatchQuickInfo';
import './liveChannelInfoDialog.scss';

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

class LiveChannelInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: null,
            countryData: null,
            isLoading: true,
            showFullCountryList: null,
            inBasket: this.getBasketState(),
            error: false,
            errorMsg: '',
        };
    }

    componentDidMount() {
        const streamingDeviceReq =
            isMicrofrontend && this.props.isHistoryExportEnabledForQuarkus
                ? Xhr.backendRequestLbcQuarkus(
                      `/streaming/devices/${this.props.event.uri}`,
                      null,
                      'GET',
                      null,
                      false
                  )
                : Xhr.backendRequest(
                      `/streaming/devices/${this.props.event.uri}`,
                      null,
                      'GET',
                      null,
                      false
                  );
        streamingDeviceReq.then(
            response => {
                this.setState({
                    countryData: response.deviceInfo,
                    selectedTab: this.setSelectedTab(
                        Object.keys(response.deviceInfo)
                    ),
                    isLoading: false,
                });
            },
            error => {
                this.setState({
                    error: true,
                    errorMsg: error.message,
                });
            }
        );
    }

    render() {
        const quickInfo = (
            <MatchQuickInfo
                event={this.props.event}
                optionalKeyValue={this.getQuickInfoGeoRestrictionKeyVal()}
            />
        );

        const productTabs = this.getTabs();
        return (
            <div className="dialog-content-wrapper live-channel-dialog">
                <div className="content live-channel">
                    {quickInfo}
                    {productTabs.length ? (
                        <Tabs
                            data={productTabs}
                            selectedTab={this.getSelectedTab()}
                            onlyHorizontal={true}
                            onTabClicked={this.onTabClick.bind(this)}
                        />
                    ) : (
                        ''
                    )}
                    {this.buildError()}
                    {!this.state.error && productTabs.length
                        ? this.buildPane()
                        : ''}
                    {!this.state.error ? this.buildBasketButton() : ''}
                </div>
            </div>
        );
    }

    getTabs() {
        const tabs = [];
        for (const productKey in AV_PRODUCT.ID) {
            if (AV_PRODUCT.ID.hasOwnProperty(productKey)) {
                const productId = AV_PRODUCT.ID[productKey];
                const product = this.getSubProductById(productId);
                if (product) {
                    tabs.push({
                        text:
                            AV_PRODUCT.SHORTNAME[productId.toString()] +
                            (productId === AV_PRODUCT.ID.LCR ? ' (Flex)' : ''),
                        icon: '',
                        value: productId,
                    });
                }
            }
        }
        return tabs;
    }

    getSelectedTab() {
        return this.state.selectedTab;
    }

    onTabClick(adapterId) {
        this.setState({
            selectedTab: adapterId,
        });
    }

    getQuickInfoGeoRestrictionKeyVal() {
        const productsWithRestrictions = this.getGeoRestrictedSubProducts();
        return {
            key: 'Geo restrictions applied',
            value: productsWithRestrictions.length
                ? `Yes (${productsWithRestrictions.join(', ')})`
                : 'No',
        };
    }

    isSubProductOptionalContent() {
        return isSubProductOptionalContent(this.getSubProductByCurrentTab());
    }

    isSubProductGeoRestricted() {
        const subProduct = this.getSubProductByCurrentTab();
        return (
            !subProduct.isDisabled &&
            subProduct.countriesNotAvailable.countries.length
        );
    }

    isSubProductGeoAllowed() {
        const productCountryData = this.getCountryDataForCurrentTab();
        return (
            productCountryData.find(d => d.countries.length > 0) !== undefined
        );
    }

    getGeoRestrictedSubProducts() {
        const list = [];
        Object.values(AV_PRODUCT.KEY).forEach(acronym => {
            const subProduct = this.props.event.products[acronym];
            if (subProduct && subProduct.countriesNotAvailable.count) {
                list.push(acronym.toUpperCase());
            }
        });
        return list;
    }

    hasProductVariant() {
        const avProduct = this.getSubProductByCurrentTab().type;
        const variant = Object.values(this.props.event.products).filter(
            product =>
                product.type.includes(`${avProduct}-4sight`) ||
                product.type.includes(`${avProduct}-as`)
        );
        return variant.length > 0 ? variant : undefined;
    }

    buildPane() {
        if (this.state.isLoading) {
            return <BusySpinner />;
        }

        return (
            <div className="tab-pane">
                <div className="row">
                    <div className="col product-header">
                        {this.getProductName()}
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.buildSectionWarnings()}</div>
                </div>
                <div className="row">
                    <div className="col">
                        {this.hasProductVariant() ? (
                            <div className="variant-header">Standard feed</div>
                        ) : (
                            ''
                        )}
                        {this.buildSectionBookingStatus()}
                        {this.buildSectionGeoRestrictions()}
                        {this.hasProductVariant() ? (
                            <div className="variant-block">
                                <div className="variant-header">
                                    {constants.AV_4SIGHT} feed
                                </div>
                                {this.buildVariantSectionBookingStatus()}
                                {this.buildVariantSectionGeoRestrictions()}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.buildSectionInfo()}</div>
                </div>
            </div>
        );
    }

    buildSectionWarnings() {
        const warnings = [];

        if (this.isSubProductGeoRestricted()) {
            if (this.isSubProductGeoAllowed()) {
                warnings.push(
                    <li key="warn-geo-na">
                        Please note that there are countries, in which the
                        content of this product will not be available.
                    </li>
                );
            } else {
                warnings.push(
                    <li key="warn-geo">
                        Please note that the content of this product is not
                        available in your countries, due to geo-restrictions.
                    </li>
                );
            }
        }

        if (this.isSubProductOptionalContent()) {
            warnings.push(
                <li key="warn-optional-content">
                    Please note that this event is part of an Optional Content
                    package type.
                </li>
            );
        }

        if (this.isSubProductOnlyBuyable()) {
            warnings.push(
                <li key="warn-buyable">
                    The event for the selected product is not part of any
                    contractual packages - if you would like to buy it, please
                    contact your sales representative.
                </li>
            );
        }

        return <ul className="lc-warnings">{warnings}</ul>;
    }

    buildSectionInfo() {
        const info = [];

        if (this.isSubProductOptionalContent()) {
            info.push(
                <li key="info-optional-content">
                    This event belongs to an Optional Content package type - if
                    you book this content, you agree to pay based on the rate
                    card defined in your contract.
                </li>
            );
        }

        if (this.isSubProductGeoRestricted()) {
            info.push(
                <li key="info-geo">
                    In order to get more information on geo-restriction and
                    allowed countries, please contact your sales
                    representatives.
                </li>
            );
        }
        return <ul className="lc-info">{info}</ul>;
    }

    buildSectionGeoRestrictions() {
        const subProductCountryData = this.getCountryDataForCurrentTab();
        const returnRows = [];
        let joinGeoRestrictions = false;
        const deviceNames = {
            1: 'Desktop',
            2: 'Mobile',
        };

        if (
            subProductCountryData.length === 2 &&
            subProductCountryData[0].countries.toString() ===
                subProductCountryData[1].countries.toString() &&
            subProductCountryData[0].countriesRestricted.toString() ===
                subProductCountryData[1].countriesRestricted.toString()
        ) {
            // In this case we display restriction/allowed -rows for devices separately
            joinGeoRestrictions = true;
        }

        for (let i = 0; i < 2; i++) {
            if (!subProductCountryData.hasOwnProperty(i)) {
                continue;
            }

            const deviceName = deviceNames[subProductCountryData[i].id];
            // At least for now there is max 2 device-categories
            if (subProductCountryData[i].countries !== undefined) {
                const key = `${this.getSelectedTab()}-allowed-${i}`;

                returnRows.push(
                    this.buildCountryListRow(
                        subProductCountryData[i].countries,
                        'Allowed countries/locations',
                        deviceName,
                        joinGeoRestrictions,
                        key
                    )
                );
            }
            if (subProductCountryData[i].countriesRestricted !== undefined) {
                const key = `${this.getSelectedTab()}-restricted-${i}`;

                returnRows.push(
                    this.buildCountryListRow(
                        subProductCountryData[i].countriesRestricted,
                        'Restricted countries/locations',
                        deviceName,
                        joinGeoRestrictions,
                        key
                    )
                );
            }

            if (joinGeoRestrictions) {
                break;
            }
        }

        return returnRows;
    }

    buildVariantSectionGeoRestrictions() {
        const subProductCountryData = this.getCountryDataForVariant();
        const returnRows = [];
        let joinGeoRestrictions = false;
        const deviceNames = {
            1: 'Desktop',
            2: 'Mobile',
        };

        if (
            subProductCountryData.length === 2 &&
            subProductCountryData[0].countries.toString() ===
                subProductCountryData[1].countries.toString() &&
            subProductCountryData[0].countriesRestricted.toString() ===
                subProductCountryData[1].countriesRestricted.toString()
        ) {
            // In this case we display restriction/allowed -rows for devices separately
            joinGeoRestrictions = true;
        }

        for (let i = 0; i < 2; i++) {
            if (!subProductCountryData.hasOwnProperty(i)) {
                continue;
            }

            const deviceName = deviceNames[subProductCountryData[i].id];
            // At least for now there is max 2 device-categories
            if (subProductCountryData[i].countries !== undefined) {
                const key = `${this.getSelectedTab()}-allowed-${i}`;

                returnRows.push(
                    this.buildCountryListRow(
                        subProductCountryData[i].countries,
                        'Allowed countries/locations',
                        deviceName,
                        joinGeoRestrictions,
                        key
                    )
                );
            }
            if (subProductCountryData[i].countriesRestricted !== undefined) {
                const key = `${this.getSelectedTab()}-restricted-${i}`;

                returnRows.push(
                    this.buildCountryListRow(
                        subProductCountryData[i].countriesRestricted,
                        'Restricted countries/locations',
                        deviceName,
                        joinGeoRestrictions,
                        key
                    )
                );
            }

            if (joinGeoRestrictions) {
                break;
            }
        }

        return returnRows;
    }

    buildCountryListRow(
        countries,
        header,
        deviceName,
        joinGeoRestrictions,
        key
    ) {
        const limitCountries = this.state.showFullCountryList !== key;
        const className = limitCountries ? 'country-list' : 'country-list-all';

        const label = (
            <span className="country-label">
                {header}
                <br />
                {joinGeoRestrictions ? '(Desktop, Mobile)' : `(${deviceName})`}
            </span>
        );

        // Update state with country-list to display full.
        const setShowFullCountryListState = section => {
            // If same is clicked again -> close by setting to null
            const newVal =
                section === this.state.showFullCountryList ? null : section;
            this.setState({
                showFullCountryList: newVal,
            });
        };

        return (
            <div className="key-value" key={key}>
                {label}
                <span
                    onClick={() => setShowFullCountryListState(key)}
                    className={className}
                >
                    {this.listCountries(countries, limitCountries)}
                </span>
            </div>
        );
    }

    getSubProductById(id) {
        switch (id) {
            case AV_PRODUCT.ID.LCO:
                return this.props.event.products.lco;
            case AV_PRODUCT.ID.LCR:
                return this.props.event.products.lcr;
            case AV_PRODUCT.ID.LCT:
                return this.props.event.products.lct;
            default:
                throw new Error(`Invalid subProductId ${id}`);
        }
    }

    getSubProductByCurrentTab() {
        return this.getSubProductById(this.getSelectedTab());
    }

    /* "Full product" has complete deviceCategory-info. Fetched in mounting comp. */
    getCountryDataForCurrentTab() {
        const key = AV_PRODUCT.KEY[this.getSelectedTab()];
        return this.state.countryData[key] || [];
    }

    getCountryDataForVariant() {
        const key = this.hasProductVariant()[0].type;
        return this.state.countryData[key] || [];
    }

    isSubProductOnlyBuyable() {
        /* If booking status is N/A that means that either country-restrictions or package-limit i reached.
         *  Still buyable if client contacts sales-representative */
        return (
            this.getSubProductByCurrentTab().clientPackageType === null ||
            [BOOKING_STATUS.BUYABLE, BOOKING_STATUS.NOT_AVAILABLE].includes(
                this.getSubProductByCurrentTab().bookingStatusId
            )
        );
    }

    buildSectionBookingStatus() {
        const bookingStatus =
            this.getSubProductByCurrentTab().bookingStatusId !== null
                ? BOOKING_STATUS_NAME[
                      this.getSubProductByCurrentTab().bookingStatusId
                  ]
                : 'Buyable';

        return (
            <div className="key-value">
                <span className="product-status">Product Status</span>
                <span className="product-status">
                    {bookingStatus.toUpperCase()}
                </span>
            </div>
        );
    }

    buildVariantSectionBookingStatus() {
        const variant = this.hasProductVariant();
        const bookingStatus =
            variant[0].bookingStatusId !== null
                ? BOOKING_STATUS_NAME[variant[0].bookingStatusId]
                : 'Buyable';

        return (
            <div className="key-value">
                <span className="product-status">Product Status</span>
                <span className="product-status">
                    {bookingStatus.toUpperCase()}
                </span>
            </div>
        );
    }

    fixCommaInCountryName = country => {
        if (country.indexOf(',')) {
            country = country
                .split(/\s*,\s*/)
                .reverse()
                .join(' ');
        }
        return country;
    };

    addCountrySubDivs = (countryName, country, subDiv, limit, limitTo) => {
        if (!subDiv.length) {
            return countryName;
        }

        let subDivsHidden = 0;
        if (subDiv.length > limitTo) {
            subDivsHidden = subDiv.length - limitTo;
        }
        subDiv = subDiv.slice(0, limit ? limitTo : 100).map(someCountry => {
            return this.props.countries[someCountry];
        });
        if (limit) {
            subDiv.push(`(click for ${subDivsHidden} more)`);
        }

        return `[${countryName}: ${subDiv.join(', ')}]`;
    };

    listCountries(countries, limit) {
        if (limit === undefined) {
            limit = true;
        }

        const limitTo = 5;
        const fullNames = [];

        if (!Object.keys(countries).length) {
            return 'None';
        }

        let i = 0;

        for (const country in countries) {
            if (!countries.hasOwnProperty(country)) {
                continue;
            }

            i++;

            let countryName = this.fixCommaInCountryName(
                this.props.countries[country]
            );

            const subDiv = countries[country];

            countryName = this.addCountrySubDivs(
                countryName,
                country,
                subDiv,
                limit,
                limitTo
            );

            if (limit && i > limitTo) {
                const numberOfHiddenCountries =
                    Object.keys(countries).length - limitTo;
                fullNames.push(
                    `+${numberOfHiddenCountries} (click to show all)`
                );
                break;
            }
            fullNames.push(countryName);
        }
        return fullNames.join(', ');
    }

    getProductName() {
        if (this.state.selectedTab === null) {
            return 'Loading';
        }
        const currentSubProduct = this.getSubProductByCurrentTab();
        return (
            currentSubProduct.productName +
            (this.getSubProductByCurrentTab().productId === AV_PRODUCT.ID.LCR
                ? ' (Flex)'
                : '')
        );
    }

    onAddToBasket() {
        this.props.addEvents([this.props.event], true);
        this.setState({
            inBasket: true,
        });
    }

    onRemoveFromBasket() {
        this.props.removeEvent(this.props.event.uri);
        this.setState({
            inBasket: false,
        });
    }

    getBasketState() {
        return this.props.basketItems.hasOwnProperty(this.props.event.uri);
    }

    buildBasketButton() {
        if (!eventCanBeAddedToBasket(this.props.event)) {
            return '';
        }
        if (!this.state.inBasket) {
            return (
                <Button
                    onClick={this.onAddToBasket.bind(this)}
                    type={Button.types.LARGE_BLUE_SHADOW}
                    fixedWidth={200}
                    className="add-to-basket"
                >
                    Add to checkout
                </Button>
            );
        }

        return (
            <Button
                onClick={this.onRemoveFromBasket.bind(this)}
                type={Button.types.LARGE_GREY_SHADOW}
                fixedWidth={200}
                className="remove-from-basket"
            >
                Remove from checkout
            </Button>
        );
    }

    buildError() {
        if (!this.state.error) {
            return '';
        }
        return (
            <div className="content">
                <Alert type={Alert.types.ERROR} float={false}>
                    {this.state.errorMsg}
                </Alert>
            </div>
        );
    }

    setSelectedTab(keysInData) {
        if (keysInData.indexOf('lco') !== -1) {
            return AV_PRODUCT.ID.LCO;
        }
        if (keysInData.indexOf('lcr') !== -1) {
            return AV_PRODUCT.ID.LCR;
        }
        if (keysInData.indexOf('lct') !== -1) {
            return AV_PRODUCT.ID.LCT;
        }
        throw new Error('No product found!');
    }
}

LiveChannelInfoDialog.propTypes = {
    event: PropTypes.object.isRequired,
    countries: PropTypes.object.isRequired,
    addEvents: PropTypes.func.isRequired,
    removeEvent: PropTypes.func.isRequired,
    basketItems: PropTypes.object.isRequired,
    isHistoryExportEnabledForQuarkus: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    countries: state.calendar.countries,
    basketItems: state.shoppingBasket.items,
    isHistoryExportEnabledForQuarkus:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_quarkus_streaming_devices,
});

export default connect(mapStateToProps, {
    addEvents: addEvents,
    removeEvent: removeEvent,
})(LiveChannelInfoDialog);
