import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import settings from '../../../../../utils/DefaultSettings';
import moment from '../../../../../utils/CalendarMoment';
import FilterConstants from '../../../../../constants/FilterConstants';
import { dialogTypes } from '../../../../../stores/dialog/constants';
import { addDialog } from '../../../../../stores/dialog/actions';
import Button from '../../../../formInput/Button/Button';
import AutobookingDropdownRow from '../../Viewport/AutobookingListOverview/AutobookingForm/AutobookingDropdownRow/AutobookingDropdownRow'; // need to rename this, no longer a row
import ContentBoxTitle from '../../../../ContentBoxTitle/ContentBoxTitle';
import CustomSelect from '../../../../formInput/CustomSelect/CustomSelect';
import MultiSelectDropdown from '../../../../formInput/MultiSelectDropdown/MultiSelectDropdown';
import DateRangePicker from '../../../../formInput/DateRangePicker/DateRangePicker';
import TimeRangePicker from '../../../../formInput/TimeRangePicker/TimeRangePicker';
import Switch from '../../../../Switch/Switch';
import CheckBoxIcon from '../../../../icons/CheckBoxIcon/CheckBoxIcon';
import {
    updateFilter,
    resetFilters,
    resetActiveFilters,
    activateFilters,
    toggleDisabledFilter,
} from '../../../../../stores/autobooking/actions';
import { getFilterName } from '../../../../../utils/autobookingUtils';
import { orderedSelectedFilters } from '../../../../../selectors/autobooking';
import './autobookingFilters.scss';

class AutobookingFilters extends React.Component {
    _buildFooter() {
        const [filtersAreValid, warning] = this._checkIfFiltersAreValid();
        return (
            <div className="autobooking-filters-footer">
                <div className="rules-text headline-700 text-black text-left">
                    {this.props.filteredAutobookingRulesCount}/
                    {this.props.totalAutobookingRulesCount} rule
                    {this.props.totalAutobookingRulesCount > 1 && 's'} shown
                </div>

                <Button
                    cyTarget={'ab-filter-reset'}
                    icon=""
                    type={Button.types.LARGE_GREY_BLUE}
                    fixedWidth={75}
                    onClick={this.props.resetFilters}
                >
                    RESET
                </Button>
                <Button
                    icon=""
                    type={Button.types.LARGE_GREY_BLUE}
                    fixedWidth={false}
                    onClick={this.props.resetActiveFilters}
                >
                    SHOW ALL RULES
                </Button>
                <Button
                    icon=""
                    type={Button.types.LARGE_GREY_BLUE}
                    fixedWidth={150}
                    onClick={this.props.activateFilters}
                >
                    SHOW CHANGES
                </Button>
                <Button
                    type={Button.types.LARGE_BLUE_WHITE}
                    fixedWidth={163}
                    icon={''}
                    disabled={!filtersAreValid}
                    title={warning}
                    onClick={() =>
                        // Assume that form is valid (the user should not be able to trigger this method unless the form is valid)
                        this.props.addDialog(dialogTypes.SAVE_AUTO_BOOKING)
                    }
                >
                    {this.props.editId ? 'UPDATE RULE' : 'CREATE NEW RULE'}
                </Button>
                <div className="warning">{warning}</div>
            </div>
        );
    }

    _checkIfFiltersAreValid() {
        if (this.props.orderedSelectedFilters.length === 0) {
            return [false, 'Please select filters'];
        }

        if (
            this.props.orderedSelectedFilters.includes('dates') &&
            !this._dateIsValid(this.props.formFilters)
        ) {
            return [false, 'Please select a valid date range'];
        }

        return [true, ''];
    }

    _getSportOptions() {
        const sportOptions = Object.values(this.props.sports)
            .filter(sport => !sport.isCompetitionSport)
            .map(sport => ({
                label: sport.name,
                value: sport.id,
            }));

        sportOptions.unshift({
            label: 'All',
            value: -1,
        });

        return sportOptions;
    }

    _buildUri(uri, replace, replaceWith) {
        return uri.replace(replace, replaceWith);
    }

    _buildSportDropdown() {
        return (
            <CustomSelect
                id={'filter-ab-sport'}
                onChange={sport => {
                    this.props.updateFilter({
                        sportId: sport.value,
                        sportName: sport.label, // TODO doesn't properly set the sport name?
                    });
                }}
                value={{
                    value: this.props.formFilters.sportId || -1,
                    label: this.props.formFilters.sportName || 'All',
                }}
                clearable={false}
                searchable={true}
                optionHeight={32}
                options={this._getSportOptions()}
                placeholder={'Select Sport...'}
            />
        );
    }

    _buildCategoryDropdown() {
        return (
            <AutobookingDropdownRow
                id={'filter-ab-category'}
                // warning={this._getWarning(warningKeys.CATEGORY)}
                disabled={this.props.formFilters.sportId <= 0}
                options={this.props.options.category}
                currentValue={{
                    value: this.props.formFilters.realCategoryId || -1,
                    label: this.props.formFilters.realCategoryName || 'All',
                }}
                optionName={'category'}
                parentKey={'sportId'}
                parentValue={this.props.formFilters.sportId}
                isLoading={this.props.options.isloadingcategory}
                remoteDataUri={this._buildUri(
                    settings.categoriesUri,
                    '{sportId}',
                    this.props.formFilters.sportId
                )}
                onChange={category =>
                    this.props.updateFilter({
                        realCategoryId: category.value,
                        realCategoryName: category.label,
                    })
                }
                placeholder="Select Sport first ..."
                sortValues={true}
            />
        );
    }

    _buildTournamentDropdown() {
        return (
            <AutobookingDropdownRow
                id={'filter-ab-tournament'}
                disabled={this.props.formFilters.realCategoryId <= 0}
                options={this.props.options.tournament}
                currentValue={{
                    value: this.props.formFilters.tournamentId || -1,
                    label: this.props.formFilters.tournamentName || 'All',
                }}
                optionName={'tournament'}
                parentKey={'realCategoryId'}
                parentValue={this.props.formFilters.realCategoryId || -1}
                isLoading={this.props.options.isloadingtournament}
                remoteDataUri={this._buildUri(
                    settings.tournamentsUri,
                    '{realCategoryId}',
                    this.props.formFilters.realCategoryId || -1
                )}
                onChange={tournament =>
                    this.props.updateFilter({
                        tournamentId: tournament.value,
                        tournamentName: tournament.label,
                    })
                }
                placeholder="Select Category first ..."
                sortValues={true}
            />
        );
    }

    _buildMatchDropdowns() {
        return (
            <div className="cell-content">
                <AutobookingDropdownRow
                    id={'filter-ab-teamhome'}
                    // warning={this._getWarning(warningKeys.MATCHES1)}
                    currentValue={{
                        value: this.props.formFilters.homeTeamId || -1,
                        label: this.props.formFilters.homeTeamName || 'All',
                    }}
                    disabled={this.props.formFilters.tournamentId <= 0}
                    options={this.props.options.match1}
                    optionName={'match1'}
                    parentKey={'tournamentId'}
                    parentValue={this.props.formFilters.tournamentId}
                    isLoading={this.props.options.isloadingmatch1}
                    remoteDataUri={this._buildUri(
                        settings.autobooking.tournamentInfoUri,
                        '{tournament_id}',
                        this.props.formFilters.tournamentId
                    )}
                    onChange={matchesFromTeam1 =>
                        this.props.updateFilter({
                            homeTeamId: matchesFromTeam1.value,
                            homeTeamName: matchesFromTeam1.label,
                        })
                    }
                    placeholder="Select Tournament first ..."
                    sortValues={true}
                />
                <AutobookingDropdownRow
                    id={'filter-ab-teamaway'}
                    currentValue={{
                        value: this.props.formFilters.awayTeamId || -1,
                        label: this.props.formFilters.awayTeamName || 'All',
                    }}
                    disabled={this.props.formFilters.tournamentId <= 0}
                    options={this.props.options.match2}
                    optionName={'match2'}
                    parentKey={'tournamentId'}
                    parentValue={this.props.formFilters.tournamentId}
                    isLoading={this.props.options.isloadingmatch2}
                    remoteDataUri={this._buildUri(
                        settings.autobooking.tournamentInfoUri,
                        '{tournament_id}',
                        this.props.formFilters.tournamentId
                    )}
                    onChange={matchesFromTeam2 =>
                        this.props.updateFilter({
                            awayTeamId: matchesFromTeam2.value,
                            awayTeamName: matchesFromTeam2.label,
                        })
                    }
                    placeholder="Select Tournament first ..."
                    sortValues={true}
                />
            </div>
        );
    }

    _buildProductPicker() {
        // add the as-stuff when backend for MR is ready for autobooking
        return (
            <MultiSelectDropdown
                options={['lo', 'lco', 'lcr', 'lct']}
                optionNames={{
                    lo: 'LO/LD',
                    lco: 'LCO',
                    lcr: 'LCR',
                    lct: 'LCT',
                }}
                selectedOptions={this.props.formFilters.products}
                onChange={products => {
                    this.props.updateFilter({ products });
                }}
                placeholder="LO/LD"
            />
        );
    }

    _buildTierPicker() {
        return (
            <MultiSelectDropdown
                options={['star', 'mvp', 'other']}
                selectedOptions={this.props.formFilters.tierContent}
                onChange={tierContent => {
                    this.props.updateFilter({ tierContent });
                }}
            />
        );
    }

    _buildTypePicker() {
        return (
            <MultiSelectDropdown
                options={['real', 'simulated']}
                selectedOptions={this.props.formFilters.types}
                onChange={types => {
                    this.props.updateFilter({ types });
                }}
            />
        );
    }

    _setFromDate = dateType => {
        let newDate = new Date();
        this.props.updateFilter({
            [dateType]: Math.floor(newDate.getTime() / 1000),
        });
        return newDate;
    };

    _buildDateRangePicker() {
        return (
            <DateRangePicker
                selectedFrom={
                    this.props.formFilters.startDate
                        ? new Date(this.props.formFilters.startDate * 1000)
                        : this._setFromDate('startDate')
                }
                selectedTo={
                    this.props.formFilters.endDate
                        ? new Date(this.props.formFilters.endDate * 1000)
                        : this._setFromDate('endDate')
                }
                onFromChange={from => {
                    this.props.updateFilter({
                        startDate: Math.floor(from.getTime() / 1000),
                    });
                }}
                onToChange={to =>
                    this.props.updateFilter({
                        endDate: Math.floor(to.getTime() / 1000),
                    })
                }
                disabled={this.props.disabledFilters.dates}
            />
        );
    }

    _buildTimeRangePicker() {
        return (
            <TimeRangePicker
                from={moment
                    .fromTimestamp(this.props.formFilters.startTime)
                    .toFormat('HH:mm')}
                to={moment
                    .fromTimestamp(this.props.formFilters.endTime)
                    .toFormat('HH:mm')}
                onFromChange={startTime =>
                    this.props.updateFilter({
                        startTime: moment
                            .now()
                            .set({
                                hour: startTime.split(':')[0],
                                minutes: startTime.split(':')[1],
                            })
                            .toFormat('X'),
                    })
                }
                onToChange={endTime =>
                    this.props.updateFilter({
                        endTime: moment
                            .now()
                            .set({
                                hour: endTime.split(':')[0],
                                minutes: endTime.split(':')[1],
                            })
                            .toFormat('X'),
                    })
                }
                disabled={this.props.disabledFilters.times}
            />
        );
    }

    _buildWeekdayPicker() {
        return (
            <MultiSelectDropdown
                options={[0, 1, 2, 3, 4, 5, 6]}
                optionNames={{
                    0: 'Mon',
                    1: 'Tue',
                    2: 'Wed',
                    3: 'Thu',
                    4: 'Fri',
                    5: 'Sat',
                    6: 'Sun',
                }}
                selectedOptions={this.props.formFilters.daysOfWeek}
                onChange={daysOfWeek =>
                    this.props.updateFilter({ daysOfWeek: daysOfWeek.sort() })
                }
            />
        );
    }

    _buildAgeGroupPicker() {
        return (
            <MultiSelectDropdown
                options={Object.keys(FilterConstants.ageGroups)}
                optionNames={FilterConstants.ageGroups}
                selectedOptions={this.props.formFilters.ageGroups}
                onChange={ageGroups =>
                    this.props.updateFilter({
                        ageGroups: ageGroups.sort(
                            (a, b) =>
                                FilterConstants.ageGroupsOrder[a] -
                                FilterConstants.ageGroupsOrder[b]
                        ),
                    })
                }
            />
        );
    }

    _buildSurchargeSwitch() {
        return (
            <Switch
                selected={!!this.props.formFilters.withoutSurchargeOnly}
                onChange={withoutSurchargeOnly =>
                    this.props.updateFilter({ withoutSurchargeOnly })
                }
                disabled={this.props.disabledFilters.surcharge}
            />
        );
    }

    _buildLoConfigSwitch() {
        return (
            <Switch
                selected={
                    !!this.props.formFilters.isMatchActiveInLiveoddsConfig
                }
                onChange={isMatchActiveInLiveoddsConfig =>
                    this.props.updateFilter({ isMatchActiveInLiveoddsConfig })
                }
                disabled={this.props.disabledFilters.loconfig}
            />
        );
    }

    _buildSingleSwitch() {
        return (
            <Switch
                selected={!!this.props.formFilters.bookableWithSinglePackage}
                onChange={bookableWithSinglePackage =>
                    this.props.updateFilter({
                        bookableWithSinglePackage,
                    })
                }
                disabled={this.props.disabledFilters.single}
            />
        );
    }

    buildFilterToggle(filter) {
        if (
            ['dates', 'times', 'surcharge', 'loconfig', 'single'].includes(
                filter
            )
        ) {
            return (
                <div
                    className="toggle"
                    onClick={this.props.toggleDisabledFilter.bind(this, filter)}
                >
                    <ContentBoxTitle title={this.getFilterTitle(filter)}>
                        Active
                    </ContentBoxTitle>
                    <CheckBoxIcon
                        selected={!this.props.disabledFilters[filter]}
                        color="color-blue-700"
                    />
                </div>
            );
        }
    }

    getFilterTitle(filter) {
        switch (filter) {
            case 'matches':
                return 'Not all tournaments have this option available';
            case 'loconfig':
                return 'Active in Liveodds Configuration';
            case 'times':
                return 'Event has start time between';
            default:
                return '';
        }
    }

    showFilter(filter) {
        switch (filter) {
            case 'sport':
                return this._buildSportDropdown();
            case 'category':
                return this._buildCategoryDropdown();
            case 'tournament':
                return this._buildTournamentDropdown();
            case 'matches':
                return this._buildMatchDropdowns();
            case 'products':
                return this._buildProductPicker();
            case 'types':
                return this._buildTypePicker();
            case 'tiers':
                return this._buildTierPicker();
            case 'dates':
                return this._buildDateRangePicker();
            case 'times':
                return this._buildTimeRangePicker();
            case 'weekdays':
                return this._buildWeekdayPicker();
            case 'agegroups':
                return this._buildAgeGroupPicker();
            case 'surcharge':
                return this._buildSurchargeSwitch();
            case 'loconfig':
                return this._buildLoConfigSwitch();
            case 'single':
                return this._buildSingleSwitch();

            // to add a new autobooking filter/column, edit here and every other place this comment exists

            default:
                return '';
        }
    }

    _dateIsValid(formFilters) {
        const mmFrom = moment.fromTimestamp(formFilters.startDate),
            mmTo = moment.fromTimestamp(formFilters.endDate);

        return mmTo > mmFrom || mmFrom.hasSame(mmTo, 'day');
    }

    _correctOrder(filter1, filter2) {
        return (
            this.props.formFilters[filter1] <= this.props.formFilters[filter2]
        );
    }

    showWarning(filter) {
        let warning = '';
        switch (filter) {
            case 'dates':
                if (!this._dateIsValid(this.props.formFilters)) {
                    warning = 'End date must be after start date.';
                }
                break;
            default:
                break;
        }

        return <div className="warning ui-200">{warning}</div>;
    }

    render() {
        return (
            <>
                <div className="autobooking-container autobooking-filters">
                    <div className="autobooking-row">
                        {this.props.orderedSelectedFilters.map(filter => (
                            <div
                                className={`autobooking-cell ${filter}`}
                                key={filter}
                            >
                                <div className="filter">
                                    <ContentBoxTitle
                                        title={this.getFilterTitle(filter)}
                                        className={
                                            this.props.disabledFilters[
                                                filter
                                            ] && 'disabled'
                                        }
                                    >
                                        {getFilterName(filter)}
                                    </ContentBoxTitle>
                                    {this.showFilter(filter)}
                                    {this.showWarning(filter)}
                                </div>
                                {this.buildFilterToggle(filter)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="timeWarning">
                    {Math.ceil(
                        ((this.props.formFilters.endDate -
                            this.props.formFilters.startDate) *
                            1000) /
                            (1000 * 3600 * 24)
                    ) > 29
                        ? 'The rule created/edited will autobook events 30 days in advance!'
                        : ''}
                </div>
                {this._buildFooter()}
            </>
        );
    }
}

AutobookingFilters.propTypes = {
    formFilters: PropTypes.object,
    orderedSelectedFilters: PropTypes.array,
    bookmakerFeatures: PropTypes.object.isRequired,
    filteredAutobookingRulesCount: PropTypes.number,
    totalAutobookingRulesCount: PropTypes.number,
    disabledFilters: PropTypes.object,
    toggleDisabledFilter: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    resetActiveFilters: PropTypes.func.isRequired,
    activateFilters: PropTypes.func.isRequired,
    editId: PropTypes.number,
    sports: PropTypes.object.isRequired,
    addDialog: PropTypes.func.isRequired,
    options: PropTypes.object,
};

const mapStateToProps = state => ({
    formFilters: state.autobooking.formFilters,
    orderedSelectedFilters: orderedSelectedFilters(state.autobooking),
    bookmakerFeatures: state.calendar.auth.userInfo.bookmakerInfo.features,
    disabledFilters: state.autobooking.disabledFilters,
    options: state.autobooking.options,
    editId: state.autobooking.editId,
    sports: state.calendar.sports,
});

const mapDispatchToProps = {
    updateFilter,
    resetFilters,
    resetActiveFilters,
    toggleDisabledFilter,
    activateFilters,
    addDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutobookingFilters);
