import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Info } from 'luxon';
import Xhr from '../../../../../utils/Xhr';
import LazyTable from '../../../../LazyTable/LazyTable';
import { LazyTableData, Row, Cell } from '../../../../../models/LazyTableData';
import Button from '../../../../formInput/Button/Button';
import DropDown from '../../../../formInput/Dropdown/DropDown';
import moment from '../../../../../utils/CalendarMoment';
import constants from '../../../../../constants/Constants';
import { type as historyType } from '../../../../../stores/bookingHistory/constants';
import views from '../../../../../constants/views';
import _ from 'underscore';
import isEqual from 'react-fast-compare';
import {
    getOverview,
    shouldReloadOverview,
} from '../../../../../selectors/history';
import isCalendarBusy from '../../../../../selectors/calendarBusy';
import getBookmakerInfo from '../../../../../selectors/bookmakerInfo';
import {
    loadBookingHistory,
    loadMoreBookingHistory,
} from '../../../../../stores/bookingHistory/actions';
import { setCurrentView } from '../../../../../stores/viewport/actions';
import BusySpinner from '../../../../BusySpinner/BusySpinner';
import FontIcon from '../../../../icons/FontIcon/FontIcon';
import SportIcon from '../../../../icons/SportIcon/SportIcon';
import './AVbookingHistory.scss';

const monthNameToNumber = name =>
    Info.months('long', { locale: 'en-US' }).indexOf(name) + 1;

const allColumns = {
    MONTH: { id: 1, header: 'Month', classes: [] },
    INVOICE: { id: 2, header: 'Invoiced matches', classes: ['text-right'] },
    EXPORT: {
        id: 3,
        header: 'export_dropdown',
        classes: [],
    },
};
const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const createMonthYearHash = (month, year) => {
    return month + year;
};

class BookingHistory extends React.Component {
    state = {
        downloadType: 'csv',
        expandedRows: [],
        columns: [allColumns.MONTH, allColumns.INVOICE, allColumns.EXPORT],
        from: -4,
        to: 0,
    };

    componentDidMount() {
        if (this.props.shouldReload) {
            this.props.loadBookingHistory(
                this.state.from,
                this.state.to,
                this.props.type,
                this.props.subProductId
            );
        }
    }

    _buildLazyTableData(matchInfo) {
        const cellWidths = this.props.cellWidths.length
            ? this.props.cellWidths
            : this._getCellWidths();
        const lazyTableData = new LazyTableData(cellWidths),
            yearsSorted = Object.keys(matchInfo).sort(
                (yearA, yearB) => yearB - yearA
            ),
            allMonthNamesSorted = Info.months().map(m => m.toLowerCase());

        for (const year of yearsSorted) {
            const months = matchInfo[year],
                monthNamesSorted = this._sortMonthNames(
                    Object.keys(months),
                    allMonthNamesSorted
                );

            for (const monthName of monthNamesSorted) {
                const [subRows, columns] = this._buildSubRowsWithSums(
                    year,
                    months,
                    monthName
                );

                lazyTableData.addRow(
                    this._buildSumRow(year, monthName, columns)
                );
                for (const subRow of subRows) {
                    lazyTableData.addRow(subRow);
                }
            }
        }

        const loadMoreCellContent = !this.props.matchInfo.loadingMore ? (
            <div className="load-more-button-container">
                <Button
                    type={Button.types.LARGE_TRANSPARENT_SOLID}
                    icon=""
                    onClick={() => {
                        if (this.props.matchInfo.loadingMore) {
                            return;
                        }
                        this.loadMore();
                    }}
                    fixedWidth={false}
                >
                    Load More
                </Button>
            </div>
        ) : (
            <BusySpinner margin={'auto'} />
        );

        const loadmoreButton = new Row([
            new Cell(loadMoreCellContent, ['load-more'], null, '', 3),
        ]);
        lazyTableData.addRow(loadmoreButton);

        return lazyTableData;
    }

    _sortMonthNames(monthNames, allMonthNamesSorted) {
        return monthNames.sort((a, b) => {
            return (
                allMonthNamesSorted.indexOf(b.toLowerCase()) -
                allMonthNamesSorted.indexOf(a.toLowerCase())
            );
        });
    }

    _getCellWidths() {
        let isLast, width;
        const amountHeaderColumns = Object.keys(this.state.columns).length,
            amountDynamic = amountHeaderColumns - 2,
            firstCellWidth = 22,
            lastCellWidth = 16;
        let difference = 100 - firstCellWidth - lastCellWidth;
        const dynamicWidth = Math.floor(difference / amountDynamic),
            cellWidths = [firstCellWidth];

        for (let i = 0; i < amountDynamic; i++) {
            isLast = i === amountDynamic - 1;
            width = isLast ? difference : dynamicWidth;
            cellWidths.push(width);
            difference -= width;
        }
        cellWidths.push(lastCellWidth);

        return cellWidths;
    }

    valueParsers = {
        number(x) {
            if (x === 0) {
                return <span className="zero-value">0</span>;
            }
            const a = String(x);
            let b = '';
            for (let i = a.length - 1, y = 0; i >= 0; i--, y++) {
                b = a[i] + (y > 0 && y % 3 === 0 ? ' ' : '') + b;
            }
            return b;
        },
        money(x) {
            return <span>{x}</span>;
        },
    };

    _buildSubRowsWithSums(year, months, month) {
        const columnToPropMapping = this._getColumnToPropMapping(),
            isExpanded = this._isExpanded(createMonthYearHash(month, year));
        let columns = [];

        const subRows = _.map(months[month], sport => {
            columns = _.map(columnToPropMapping, column => {
                const data = column.transform(sport);
                column.value = data.countable;
                column.countable += data.countable;

                return {
                    ...column,
                    element: data.element,
                };
            });

            const cells = _.map(columns, column => {
                return new Cell(column.element, column.classes);
            });

            return new Row(cells, ['child-row'], false, !isExpanded);
        });

        return [subRows, columns];
    }

    _getColumnToPropMapping() {
        let sportIcon;
        const hcn = allColumns,
            _prepareInteger = propName => {
                return sport => ({
                    countable: parseInt(sport[propName], 10),
                    element: this.valueParsers.number(sport[propName]),
                });
            };

        return this.state.columns.map(column => {
            switch (column) {
                case hcn.MONTH:
                    column.transform = sport => {
                        sportIcon = (
                            <SportIcon
                                sportId={sport.sportId}
                                sportName={sport.sportName}
                                isSupported={
                                    !!this.props.supportedSports[sport.sportId]
                                }
                            />
                        );

                        return {
                            countable: 0,
                            element: (
                                <div>
                                    {sportIcon}
                                    <span className="sportName">
                                        {sport.sportName}
                                    </span>
                                </div>
                            ),
                        };
                    };
                    break;
                case hcn.INVOICE:
                    column.transform = sport => {
                        const value = !_.isUndefined(sport.disabledBookings)
                            ? sport.invoiceBookings + sport.disabledBookings
                            : sport.invoiceBookings;
                        return {
                            countable: value,
                            element: this.valueParsers.number(value),
                        };
                    };
                    break;
                case hcn.EXPORT:
                    column.transform = () => ({
                        countable: 0,
                        element: '',
                    });
                    break;
                default:
                    console.error('Invalid column: ', column);
            }

            column.countable = 0;
            return column;
        });
    }

    _isExpanded(monthYearHash) {
        // return whether row represented by monthYearHash is expanded
        return this.state.expandedRows.indexOf(monthYearHash) >= 0;
    }

    buildExportButton(year, monthName, type) {
        const month = monthNameToNumber(monthName);
        const product = this.props.subProductId;
        const downloadLink =
            isMicrofrontend && this.props.isHistoryExportEnabled
                ? `${Xhr._getBaseUrl()}/lbc/streaming/historyExport?type=${type}&month=${month}&year=${year}&product=${product}`
                : `${Xhr._getBaseUrl()}/loweb/api/streaming/historyExport?type=${type}&month=${month}&year=${year}&product=${product}`;
        return (
            <a href={downloadLink} download onClick={e => e.stopPropagation()}>
                <Button
                    type={Button.types.LARGE_BLUE_WHITE}
                    icon=""
                    fixedWidth={false}
                >
                    Download Report
                </Button>
            </a>
        );
    }

    _buildSumRow(year, month, columns) {
        const monthYearHash = createMonthYearHash(month, year),
            isExpanded = this._isExpanded(monthYearHash);
        let sumRowContent, classes;

        const sumRow = new Row(
            _.map(columns, column => {
                classes = column.classes;
                if (column.id === allColumns.MONTH.id) {
                    sumRowContent = (
                        <div>
                            <FontIcon icon={isExpanded ? '' : ''} />
                            <span>{`${year} ${month}`}</span>
                        </div>
                    );
                } else if (column.id === allColumns.EXPORT.id) {
                    sumRowContent = this.buildExportButton(
                        year,
                        month,
                        this.state.downloadType
                    );
                } else {
                    sumRowContent = this.valueParsers.number(column.countable);
                }

                return new Cell(sumRowContent, classes);
            })
        );

        sumRow.onClick = this._createSumRowClickHandler(monthYearHash);
        sumRow.classes.push('date-row');

        return sumRow;
    }

    _createSumRowClickHandler(monthYearHash) {
        return () => this._toggleRowIsExpanded(monthYearHash);
    }

    _toggleRowIsExpanded(monthYearHash) {
        const index = this.state.expandedRows.indexOf(monthYearHash),
            newExpandedRows = [...this.state.expandedRows];

        if (index >= 0) {
            newExpandedRows.splice(index, 1);
        } else {
            newExpandedRows.push(monthYearHash);
        }
        this.setState({
            expandedRows: newExpandedRows,
        });
    }

    _buildMainHeader(componentName) {
        return (
            <div className="header">
                <div className="sub-header">{componentName}</div>
            </div>
        );
    }

    _buildHeaderRow() {
        const cells = _.map(this.state.columns, column => {
            const columnHeader =
                column.header === 'export_dropdown' ? (
                    <DropDown
                        onChange={downloadType =>
                            this.setState({ downloadType })
                        }
                        options={[
                            { value: 'csv', text: 'CSV' },
                            { value: 'xml', text: 'XML' },
                        ]}
                        value={this.state.downloadType}
                        label="Download report as"
                        labelLeft
                    />
                ) : (
                    column.header
                );
            return new Cell(columnHeader, column.classes, null, column.tooltip);
        });

        const row = new Row(cells);
        row.height = this.props.headerHeight;

        return row;
    }

    _getLazyTableId() {
        switch (this.props.type) {
            case historyType.LCO:
                return constants.LAZY_TABLE_LCO_HISTORY;
            case historyType.LCR:
                return constants.LAZY_TABLE_LCR_HISTORY;
            case historyType.LCT:
                return constants.LAZY_TABLE_LCT_HISTORY;
            default:
                console.error('Invalid type: ' + this.props.type);
                return undefined;
        }
    }

    render() {
        let content = '',
            mainHeader = '';

        if (this.props.matchInfo.data) {
            const lazyTableData = this._buildLazyTableData(
                this.props.matchInfo.data
            );
            lazyTableData.setHeaderRow(this._buildHeaderRow());

            content = (
                <LazyTable
                    id={this._getLazyTableId()}
                    lazyTableData={lazyTableData}
                />
            );

            mainHeader = this._buildMainHeader(this.props.tableName);
        }

        const busySpinner =
            !this.props.matchInfo || this.props.matchInfo.loading ? (
                <BusySpinner />
            ) : (
                ''
            );

        return (
            <div className="av-booking-history">
                {mainHeader}
                {content}
                {busySpinner}
            </div>
        );
    }

    loadMore() {
        const from = this.state.from - 3;
        const to = this.state.from - 1;

        this.props.loadMoreBookingHistory(
            from,
            to,
            this.props.type,
            this.props.subProductId
        );

        this.setState({
            from: from,
            to: to,
        });
    }
}

BookingHistory.propTypes = {
    type: PropTypes.oneOf(Object.values(historyType)),
    subProductId: PropTypes.number,
    loadBookingHistory: PropTypes.func.isRequired,
    loadMoreBookingHistory: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    shouldReload: PropTypes.bool.isRequired,
    matchInfo: PropTypes.object.isRequired,
    bookmakerInfo: PropTypes.object.isRequired,
    isCalendarBusy: PropTypes.bool.isRequired,
    tableName: PropTypes.string.isRequired,
    headerHeight: PropTypes.number.isRequired,
    cellWidths: PropTypes.array.isRequired,
    supportedSports: PropTypes.object.isRequired,
    isHistoryExportEnabled: PropTypes.bool.isRequired,
};

BookingHistory.defaultProps = {
    width: 480,
    height: 360,
    headerHeight: 24,
    cellWidths: [],
    tableName: 'Booking History',
    subProductId: undefined,
};

const mapStateToProps = (state, ownProps) => ({
    matchInfo: getOverview(state, ownProps.type),
    shouldReload: shouldReloadOverview(state, ownProps.type),
    bookmakerInfo: getBookmakerInfo(state.calendar),
    isCalendarBusy: isCalendarBusy(state),
    size: state.calendar.size,
    supportedSports: state.calendar.sports,
    isHistoryExportEnabled:
        !!state.calendar.auth.userInfo.bookmakerInfo.features
            .enable_lbc_av_history_overview,
});

const mapDispatchToProps = {
    loadBookingHistory,
    setCurrentView,
    loadMoreBookingHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
