import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { AV_PRODUCT } from '../../../../../constants/audioVisual';
import views from '../../../../../constants/views';
import { ruleCountSelector } from '../../../../../selectors/autobooking';
import { isEventView, previousView } from '../../../../../selectors/viewport';
import { refetchEventsForDay } from '../../../../../stores/calendar/actions';
import { setCurrentView } from '../../../../../stores/viewport/actions';
import Button from '../../../../formInput/Button/Button';
import ButtonDropdown from '../../../../formInput/ButtonDropdown/ButtonDropdown';
import './viewSwitcher.scss';

export const viewSwitcherSizes = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

const viewSwitcherSizesOrdered = [
    viewSwitcherSizes.LARGE,
    viewSwitcherSizes.MEDIUM,
    viewSwitcherSizes.SMALL,
];

const viewSwitcherWidth = {
    medium: 675, //675,
    large: 749,
    small: 60,
};

const getAvProductFullName = productId => {
    if (AV_PRODUCT.FULLNAME[productId]) {
        return AV_PRODUCT.FULLNAME[productId];
    }
    console.error(`Invalid product id: ${productId}`);
    return 'UNKNOWN';
};

const getAvProductHistoryViewType = productId => {
    switch (productId) {
        case AV_PRODUCT.ID.LCO:
            return views.VIEWPORT_TYPE_LCO_BOOKING_HISTORY;
        case AV_PRODUCT.ID.LCR:
            return views.VIEWPORT_TYPE_LCR_BOOKING_HISTORY;
        case AV_PRODUCT.ID.LCT:
            return views.VIEWPORT_TYPE_LCT_BOOKING_HISTORY;
        default:
            console.error(`Invalid product id: {productId}`);
            return undefined;
    }
};

export class ViewSwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.viewSwitcherRef = React.createRef();
        this.state = {
            renderSize: this.props.forceSize || viewSwitcherSizes.SMALL,
            startTime: Date.now(),
        };
    }

    componentDidMount() {
        if (this.props.forceSize) {
            return;
        }
        this.resizeObserver = new ResizeObserver(entries => {
            const availableSize = entries[0].contentRect.width;
            let renderSize = viewSwitcherSizes.SMALL;
            for (const size of viewSwitcherSizesOrdered) {
                if (availableSize > viewSwitcherWidth[size]) {
                    renderSize = size;
                    break;
                }
            }
            if (renderSize !== this.state.renderSize) {
                this.setState({ renderSize });
            }
        });
        this.resizeObserver.observe(this.viewSwitcherRef.current);
        window.addEventListener('beforeunload', this.logTimeSpent);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect();
        window.removeEventListener('beforeunload', this.logTimeSpent);
        this.logTimeSpent();
    }

    logTimeSpent = () => {
        const { startTime } = this.state;
        const timeSpent = (Date.now() - startTime) / 1000; // convert to seconds
        if (window && window._paq) {
            window._paq.push([
                'setCustomDimension',
                1,
                this.props.auth.userInfo.bookmakerId,
            ]);
            window._paq.push([
                'trackEvent',
                'Tab',
                'Time Spent',
                this.props.currentView,
                timeSpent,
            ]);
        }
    };

    formatAutobookingCount(count) {
        if (count < 1000) {
            return count;
        }
        if (count > 10000) {
            return `${Math.floor(count / 1000)}k`;
        }
        // between 1k and 10k, show one extra digit so we always have at least two
        return `${Math.floor(count / 100) / 10}k`;
    }

    getButtonsGroup() {
        const bookmakerInfo = this.props.auth.userInfo.bookmakerInfo;
        const access = this.props.access;
        const btnGroups = [];

        // event view buttons
        btnGroups.push([
            {
                text: 'List',
                selected:
                    this.props.currentView ===
                    views.VIEWPORT_TYPE_LIST_CONDENSED,
                semiSelected: this.isSemiSelected(
                    views.VIEWPORT_TYPE_LIST_CONDENSED
                ),
                type: views.VIEWPORT_TYPE_LIST_CONDENSED,
                icon: '',
            },
            {
                text: 'Schedule Overview',
                selected:
                    this.props.currentView ===
                    views.VIEWPORT_TYPE_TIMELINE_GADGET,
                semiSelected: this.isSemiSelected(
                    views.VIEWPORT_TYPE_TIMELINE_GADGET
                ),
                type: views.VIEWPORT_TYPE_TIMELINE_GADGET,
                icon: '',
            },
        ]);

        // other buttons (second group)
        const group = [
            {
                text: 'History',
                selected:
                    this.props.currentView ===
                    views.VIEWPORT_TYPE_BOOKING_HISTORY,
                semiSelected: this.isSemiSelected(
                    views.VIEWPORT_TYPE_BOOKING_HISTORY
                ),
                type: views.VIEWPORT_TYPE_BOOKING_HISTORY,
                icon: '',
            },
        ];

        if (
            bookmakerInfo.audioVisual.hasClient &&
            bookmakerInfo.audioVisual.products.length > 0
        ) {
            const buildButton = productId => {
                const productName = getAvProductFullName(productId);
                const viewType = getAvProductHistoryViewType(productId);
                return {
                    text: `${productName} History`,
                    selected: this.props.currentView === viewType,
                    semiSelected: this.isSemiSelected(viewType),
                    type: viewType,
                    icon: '',
                };
            };

            let liveChannelHistory = ['Live Channel History'];

            if (bookmakerInfo.audioVisual.products.length === 1) {
                liveChannelHistory.push(
                    buildButton(bookmakerInfo.audioVisual.products[0])
                );
            } else {
                liveChannelHistory = liveChannelHistory.concat(
                    bookmakerInfo.audioVisual.products.map(productId =>
                        buildButton(productId)
                    )
                );
            }

            group.push(liveChannelHistory);
        }
        btnGroups.push(group);

        // this.props.autobookingRuleCount may be 0 if rules not loaded, in which case we fall back on bookmakerInfo
        const abCount =
            this.props.autobookingRuleCount ||
            this.props.auth.userInfo.bookmakerInfo.autobookingRuleCount;

        if (!this.props.isReseller || access.admin) {
            const autobookingCount = this.formatAutobookingCount(abCount);
            btnGroups.push([
                {
                    text: `Autobooking (${autobookingCount})`,
                    selected:
                        this.props.currentView ===
                        views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW,
                    semiSelected: this.isSemiSelected(
                        views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW
                    ),
                    type: views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW,
                    icon: '',
                },
            ]);
        }

        if (Object.keys(access).length) {
            const adminTabs = [
                {
                    text: 'Admin',
                    selected:
                        this.props.currentView === views.VIEWPORT_TYPE_ADMIN,
                    semiSelected: this.isSemiSelected(
                        views.VIEWPORT_TYPE_ADMIN
                    ),
                    type: views.VIEWPORT_TYPE_ADMIN,
                    icon: '',
                },
            ];
            if (access.support) {
                adminTabs.push({
                    text: 'Inspector',
                    selected:
                        this.props.currentView ===
                        views.VIEWPORT_TYPE_INSPECTOR,
                    semiSelected: this.isSemiSelected(
                        views.VIEWPORT_TYPE_INSPECTOR
                    ),
                    type: views.VIEWPORT_TYPE_INSPECTOR,
                    icon: '',
                });
            }
            if (this.state.renderSize === viewSwitcherSizes.MEDIUM) {
                btnGroups.push([adminTabs]);
            } else {
                btnGroups.push(adminTabs);
            }
        }

        return btnGroups;
    }

    clickHandler(viewUid, isSelected) {
        if (!isSelected) {
            this.logTimeSpent();

            this.setState({
                startTime: Date.now(),
            });
            this.props.setCurrentView(viewUid); // set new view
            if (isEventView(viewUid)) {
                this.props.refetchEventsForDay(viewUid);
            }
        }
    }

    isSemiSelected(type) {
        return (
            this.props.currentView === views.VIEWPORT_TYPE_ERROR &&
            this.props.previousView === type
        );
    }

    buildButtons(btnGroups) {
        if (this.state.renderSize === viewSwitcherSizes.SMALL) {
            return this.buildDropdownButton(btnGroups);
        }
        return this.buildDefaultButtonView(btnGroups);
    }

    getButtonType(selected, semiSelected) {
        if (selected) {
            return Button.types.LARGE_TRANSPARENT_SOLID_ACTIVE;
        }

        if (semiSelected) {
            return Button.types.LARGE_TRANSPARENT_SOLID_PASSIVE;
        }
        return Button.types.LARGE_TRANSPARENT_SOLID;
    }

    buildButtonGroup(groupButtons, groupKey) {
        return groupButtons.map((btn, btnKey) => {
            if (Array.isArray(btn)) {
                let defaultTitle = null;

                if (typeof btn[0] === 'string') {
                    defaultTitle = btn.shift();
                }

                if (btn.length === 0) {
                    return '';
                }

                return this.buildDropdownButton([btn], defaultTitle);
            }

            return (
                <Button
                    key={['viewTypeBtn_', groupKey, btnKey].join('_')}
                    type={this.getButtonType(btn.selected, btn.semiSelected)}
                    fixedWidth={false}
                    onClick={this.clickHandler.bind(
                        this,
                        btn.type,
                        btn.selected
                    )}
                    icon={btn.icon}
                >
                    {btn.text}
                </Button>
            );
        });
    }

    buildDefaultButtonView(btnGroups) {
        return btnGroups.map((groupButtons, groupKey) =>
            this.buildButtonGroup(groupButtons, groupKey)
        );
    }

    flatten(array) {
        const newArray = [];
        for (const element of array) {
            if (Array.isArray(element)) {
                newArray.push(...this.flatten(element));
            } else {
                newArray.push(element);
            }
        }
        return newArray;
    }

    buildDropdownButton(btnGroups, defaultTitle = null) {
        let buttonList = this.flatten(btnGroups);

        const buttons = [],
            optionalDropdownProps = {};
        let title = defaultTitle,
            isSelected = false;

        // if no button is selected, choose one to use as main view
        if (!buttonList.some(button => button.selected)) {
            const selectedButtonType = previousView(
                this.props.viewHistory,
                buttonList[0].type,
                buttonList.map(b => b.type)
            );
            buttonList = buttonList.map(b => {
                if (b.type === selectedButtonType) {
                    b.mainView = true;
                }
                return b;
            });
        }

        for (const button of buttonList) {
            const btn = {};
            btn.onClick = this.clickHandler.bind(
                this,
                button.type,
                button.selected
            );
            btn.type = Button.types.LARGE_TRANSPARENT_SOLID;
            btn.selected = button.selected;
            btn.text = button.text;
            buttons.push(btn);

            if (button.selected || button.semiSelected) {
                if (defaultTitle === null) {
                    title = button.text;
                }
                isSelected = true;
            } else if (button.mainView) {
                if (title === null) {
                    title = button.text;
                }
                if (defaultTitle === null) {
                    optionalDropdownProps.mainButtonClick =
                        this.clickHandler.bind(
                            this,
                            button.type,
                            button.selected
                        );
                }
            }
        }

        return (
            <ButtonDropdown
                key={['viewswitcher', title].join('_')}
                type={
                    isSelected
                        ? Button.types.LARGE_TRANSPARENT_SOLID_ACTIVE
                        : Button.types.LARGE_TRANSPARENT_SOLID
                }
                data={{
                    mainButtonText: title,
                    buttons,
                }}
                style={{
                    height: '100%',
                }}
                {...optionalDropdownProps}
            />
        );
    }

    render() {
        return (
            <div className="view-switcher" ref={this.viewSwitcherRef}>
                {this.buildButtons(this.getButtonsGroup())}
            </div>
        );
    }
}

ViewSwitcher.propTypes = {
    currentView: PropTypes.string.isRequired,
    previousView: PropTypes.string,
    autobookingRuleCount: PropTypes.number.isRequired,
    auth: PropTypes.object.isRequired,
    size: PropTypes.object.isRequired,
    viewHistory: PropTypes.array.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    refetchEventsForDay: PropTypes.func.isRequired,
    isReseller: PropTypes.bool,
    forceSize: PropTypes.string,
    access: PropTypes.object,
};

const mapStateToProps = state => ({
    currentView: state.viewport.currentView,
    previousView: previousView(state.viewport.viewHistory),
    viewHistory: state.viewport.viewHistory || [],
    autobookingRuleCount: ruleCountSelector(state.autobooking),
    auth: state.calendar.auth,
    size: state.calendar.size,
    isReseller: state.calendar.auth.userInfo.bookmakerInfo.isReseller,
    access: state.calendar.access,
});

const mapDispatchToProps = {
    setCurrentView,
    refetchEventsForDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSwitcher);
